"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayNameModal = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const components_1 = require("@ui-kitten/components");
const context_1 = require("../context");
const components_2 = require("../components");
const theme_1 = require("../theme");
const constants_1 = require("../constants");
const DisplayNameModal = ({ onSubmit, visible = false, }) => {
    const { translations } = context_1.useLocalizationContext();
    const [name, setName] = react_1.useState('');
    const [error, setError] = react_1.useState('');
    const [submitting, setSubmitting] = react_1.useState(false);
    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await onSubmit(name);
            setError('');
        }
        catch {
            setError('An error has occurred. Please try again.');
        }
        finally {
            setSubmitting(false);
        }
    };
    const renderLoading = () => submitting
        ? () => react_1.default.createElement(components_1.Spinner, { animating: submitting, size: "small" })
        : undefined;
    return (react_1.default.createElement(components_2.Modal, { visible: visible, testID: "DisplayNameModal" },
        react_1.default.createElement(components_1.Text, { category: "h6", style: styles.modalHeader }, translations.FIRST_NAME_MODAL_HEADER_TEXT),
        react_1.default.createElement(components_1.Text, { style: styles.modalText, category: "c2" }, translations.FIRST_NAME_MODAL_INFO_TEXT),
        react_1.default.createElement(components_1.Input, { testID: "DisplayNameModal-Name", label: translations.FIRST_NAME, style: styles.modalInput, status: theme_1.ThemeStatus.Primary, onChangeText: (text) => setName(text) }),
        react_1.default.createElement(components_2.Button, { testID: "DisplayNameModal-SubmitButton", style: styles.modalButton, onPress: handleSubmit, accessoryLeft: renderLoading(), disabled: name.length < 1 || submitting }, translations.LETS_GO),
        react_1.default.createElement(components_1.Text, { status: "danger" }, error)));
};
exports.DisplayNameModal = DisplayNameModal;
const styles = react_native_1.StyleSheet.create({
    modalButton: {
        borderRadius: 18,
        marginVertical: 20,
        maxWidth: 300,
        width: '100%',
    },
    modalHeader: {
        alignSelf: 'center',
        fontWeight: '700',
        marginTop: 20,
        textAlign: 'center',
    },
    modalInput: {
        marginTop: 20,
        maxWidth: 300,
        width: '100%',
    },
    modalText: {
        alignSelf: 'center',
        color: constants_1.Colors.gray,
        fontSize: 15,
        fontWeight: '400',
        marginTop: 10,
        maxWidth: 300,
        textAlign: 'center',
        width: '100%',
    },
});
