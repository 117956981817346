"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReportById = exports.createReport = void 0;
const nanoid_1 = require("nanoid");
const firebase_1 = require("../firebase");
const reportsCollection = () => firebase_1.firestore().collection('reports');
const getHumanFriendlyId = () => {
    const alphabet = '123456789ABCDEFGHKLMNPQRSTUVWXYZabcdefghklmnpqrstuvwxyz';
    const nanoid = nanoid_1.customAlphabet(alphabet, 10);
    return nanoid();
};
function removeUndefinedProps(obj) {
    return JSON.parse(JSON.stringify(obj));
}
const reportConverter = {
    toFirestore(report) {
        return report;
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return {
            id: data === null || data === void 0 ? void 0 : data.id,
            createdAt: data === null || data === void 0 ? void 0 : data.createdAt,
            result: data === null || data === void 0 ? void 0 : data.result,
        };
    },
};
async function createReport(checkResults) {
    const reportId = getHumanFriendlyId();
    await reportsCollection()
        .withConverter(reportConverter)
        .doc(reportId)
        .set({
        id: reportId,
        createdAt: firebase_1.firestore.FieldValue.serverTimestamp(),
        result: removeUndefinedProps(checkResults),
    });
    return getReportById(reportId);
}
exports.createReport = createReport;
async function getReportById(id) {
    const report = (await reportsCollection().withConverter(reportConverter).doc(id).get()).data();
    if (!report) {
        throw new Error('not found');
    }
    return report;
}
exports.getReportById = getReportById;
