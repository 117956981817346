"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToHostAgoraId = exports.subscribeToCopilotAgoraId = exports.setAgoraCopilotId = exports.setAgoraHostId = void 0;
const firebase_1 = require("../firebase");
const helpers_1 = require("./realtime/helpers");
const setAgoraHostId = async (sessionId, hostId) => {
    return firebase_1.database().ref(`/sessions/${sessionId}/agora/hostId`).set(hostId);
};
exports.setAgoraHostId = setAgoraHostId;
const setAgoraCopilotId = async (sessionId, copilotId) => {
    return firebase_1.database()
        .ref(`/sessions/${sessionId}/agora/copilotId`)
        .set(copilotId);
};
exports.setAgoraCopilotId = setAgoraCopilotId;
const subscribeToCopilotAgoraId = (sessionId, onCopilotAgoraIdUpdate) => {
    const ref = firebase_1.database().ref(`/sessions/${sessionId}/agora/copilotId`);
    const onChange = (value) => onCopilotAgoraIdUpdate(helpers_1.toVal(value));
    // Subscribe to value changes
    ref.on('value', onChange);
    return () => ref.off('value', onChange);
};
exports.subscribeToCopilotAgoraId = subscribeToCopilotAgoraId;
const subscribeToHostAgoraId = (sessionId, onHostAgoraIdUpdate) => {
    const ref = firebase_1.database().ref(`/sessions/${sessionId}/agora/hostId`);
    const onChange = (value) => onHostAgoraIdUpdate(helpers_1.toVal(value));
    // Subscribe to value changes
    ref.on('value', onChange);
    return () => ref.off('value', onChange);
};
exports.subscribeToHostAgoraId = subscribeToHostAgoraId;
