"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildAsyncHook = void 0;
/**
 * Accepts an async function as a parameter that returns a cleanup method.
 *
 * @returns a sync function that calls the cleanup method if and when the promise successfully resolves.
 */
const buildAsyncHook = (asyncFn, onError) => {
    const cleanupPromise = asyncFn().catch((error) => {
        if (onError) {
            onError(error);
        }
    });
    return () => {
        if (!cleanupPromise) {
            return;
        }
        cleanupPromise.then((cleanupMethod) => {
            if (!cleanupMethod) {
                return;
            }
            cleanupMethod();
        });
    };
};
exports.buildAsyncHook = buildAsyncHook;
