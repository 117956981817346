"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoteConfig = void 0;
const react_1 = require("react");
const remoteConfig_1 = require("./remoteConfig");
function useRemoteConfig() {
    const [fetching, setFetching] = react_1.useState(true);
    react_1.useEffect(() => {
        // use default values if fetching takes more than 5 seconds
        const timeout = setTimeout(() => {
            setFetching(false);
        }, 5000);
        remoteConfig_1.remoteConfig()
            .fetchAndActivate()
            .finally(() => {
            setFetching(false);
            clearTimeout(timeout);
        });
    }, []);
    return { fetching };
}
exports.useRemoteConfig = useRemoteConfig;
