"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePartnersCards = void 0;
const react_1 = require("react");
const remoteConfig_1 = require("./remoteConfig");
function usePartnersCards() {
    const [cards, setCards] = react_1.useState([]);
    react_1.useEffect(() => {
        try {
            const partnersCards = JSON.parse(remoteConfig_1.remoteConfig().getValue('partners_cards').asString());
            setCards(partnersCards !== null && partnersCards !== void 0 ? partnersCards : []);
        }
        catch {
            // TODO: track exception
        }
    }, []);
    return cards;
}
exports.usePartnersCards = usePartnersCards;
