"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnvironmentCheck = void 0;
const react_1 = __importStar(require("react"));
const Step_1 = require("./common/Step");
const YesNoQuestion_1 = require("./common/YesNoQuestion");
function EnvironmentCheck({ onComplete, }) {
    const [isSchoolWifi, setIsSchoolWifi] = react_1.useState();
    const [isSchoolIssued, setIsSchoolIssued] = react_1.useState();
    react_1.useEffect(() => {
        if (isSchoolWifi !== undefined && isSchoolIssued !== undefined) {
            onComplete({
                passed: true,
                isSchoolIssued,
                isSchoolWifi,
            });
        }
    }, [isSchoolIssued, isSchoolWifi, onComplete]);
    return (react_1.default.createElement(Step_1.Step, { title: "Device and internet", description: "Please answer the following questions." },
        react_1.default.createElement(YesNoQuestion_1.YesNoQuestion, { title: "Are you currently using school WiFi?", onSelectOption: (option) => setIsSchoolWifi(option) }),
        react_1.default.createElement(YesNoQuestion_1.YesNoQuestion, { title: "Are you currently using a school-issued device?", onSelectOption: (option) => setIsSchoolIssued(option) })));
}
exports.EnvironmentCheck = EnvironmentCheck;
