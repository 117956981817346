"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TechnologyCheck = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const react_device_detect_1 = require("react-device-detect");
const StartCheck_1 = require("./StartCheck");
const StepIndicator_1 = require("./StepIndicator");
const steps_1 = require("./steps");
const Container_1 = require("../../Container");
const Header_1 = require("../../Header");
const uiKitten_1 = require("../../uiKitten");
const utils_1 = require("../../../utils");
function TechnologyCheck({ useAllChecks, onComplete, }) {
    const [started, setStarted] = react_1.useState(false);
    const [currentStep, setCurrentStep] = react_1.useState(0);
    const [report, setReport] = react_1.useState({});
    react_1.useEffect(() => {
        utils_1.logEvent(utils_1.Events.VIEW_TECHNOLOGY_CHECK);
    }, []);
    const onStepComplete = react_1.useCallback((result) => {
        setReport((r) => {
            return { ...r, ...result };
        });
        setCurrentStep((s) => ++s);
    }, []);
    const allSteps = [
        {
            label: 'Device and Internet',
            component: (react_1.default.createElement(steps_1.EnvironmentCheck, { onComplete: (data) => onStepComplete({
                    environment: data,
                }) })),
        },
        {
            label: 'Browser Check',
            component: (react_1.default.createElement(steps_1.BrowserCompatibility, { onComplete: (data) => onStepComplete({
                    device: data,
                }) })),
        },
        {
            label: 'Microphone Check',
            component: (react_1.default.createElement(steps_1.MicrophoneCheck, { onComplete: (data) => onStepComplete({
                    microphone: data,
                }) })),
        },
        {
            label: 'Speaker Check',
            component: (react_1.default.createElement(steps_1.SpeakerCheck, { onComplete: (data) => onStepComplete({
                    speaker: data,
                }) })),
        },
        {
            label: 'Connection Check',
            component: (react_1.default.createElement(steps_1.ConnectionCheck, { onComplete: (data) => onStepComplete({
                    agora: data,
                }) })),
        },
        {
            label: 'Participation Check',
            component: (react_1.default.createElement(steps_1.ParticipationCheck, { onComplete: ({ auth, rtdb, firestore }) => onStepComplete({ auth, rtdb, firestore }) })),
        },
        {
            component: (react_1.default.createElement(react_native_1.View, { style: styles.spinnerStepContainer },
                react_1.default.createElement(uiKitten_1.Spinner, { size: "giant" }),
                react_1.default.createElement(uiKitten_1.Text, { category: "s1", style: { marginTop: 10 } }, "Building report..."))),
        },
    ];
    const coreSteps = [
        allSteps[0],
        allSteps[1],
        allSteps[4],
        allSteps[5],
        allSteps[6],
    ];
    const steps = react_device_detect_1.isMobile || !useAllChecks ? coreSteps : allSteps;
    const stepsLabels = steps.map((s) => s.label || '').filter((l) => l !== '');
    react_1.useEffect(() => {
        currentStep === steps.length - 1 && onComplete && onComplete(report);
    }, [currentStep, report, onComplete, steps]);
    const onStartCheck = () => {
        setStarted(true);
        utils_1.logEvent(utils_1.Events.START_TECHNOLOGY_CHECK);
    };
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(Header_1.Header, null),
        !started ? (react_1.default.createElement(StartCheck_1.StartCheck, { onStart: onStartCheck })) : (react_1.default.createElement(Container_1.Container, null,
            currentStep < stepsLabels.length && (react_1.default.createElement(StepIndicator_1.StepIndicator, { labels: stepsLabels, currentStep: currentStep })),
            react_1.default.createElement(react_native_1.View, { style: styles.stepContainer }, steps[currentStep].component)))));
}
exports.TechnologyCheck = TechnologyCheck;
const styles = react_native_1.StyleSheet.create({
    spinnerStepContainer: {
        alignItems: 'center',
        height: 200,
        justifyContent: 'center',
    },
    stepContainer: { marginHorizontal: 30, marginTop: 50, maxWidth: 700 },
});
