"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudienceSession = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const _1 = require(".");
const context_1 = require("../context");
const firebase_1 = require("../firebase");
const sessions_1 = require("../models/sessions");
const types_1 = require("../types");
const AudienceMenuBar_1 = require("./AudienceMenuBar");
const Hearts_1 = require("./Hearts");
const DisplayNameModal_1 = require("./DisplayNameModal");
const components_1 = require("../components");
const session_1 = require("./session");
const config_1 = require("../config");
const questionsAndAnswers_1 = require("./session/questionsAndAnswers");
const SHOW_ZOOM_MODAL_TIMEOUT = config_1.Config.Firebase.enableEmulators ? 2000 : 10000;
const AudienceSession = ({ sessionId, }) => {
    var _a;
    const [session, setSession] = react_1.useState();
    const [localHeartsCount, setLocalHeartsCount] = react_1.useState(0);
    const [user, setUser] = react_1.useState();
    const [isCopilot, setIsCopilot] = react_1.useState(false);
    const [showZoomModal, setShowZoomModal] = react_1.useState(false);
    const showDisplayNamePopup = !!user && !user.fullName;
    const incrementLocalHeartsCount = react_1.useCallback(() => {
        setLocalHeartsCount((c) => c + 1);
    }, []);
    const authUserToSVUser = (authUser) => {
        var _a;
        return {
            id: authUser.uid,
            fullName: (_a = authUser.displayName) !== null && _a !== void 0 ? _a : '',
            country: 'unknown',
        };
    };
    const isHost = (_a = (session && user && session.host.id === user.id)) !== null && _a !== void 0 ? _a : false;
    react_1.useEffect(() => {
        const signInAudience = async () => {
            await firebase_1.auth().signInAnonymously();
            const authUser = firebase_1.auth().currentUser;
            if (authUser) {
                setUser(authUserToSVUser(authUser));
            }
            setIsCopilot(false);
        };
        firebase_1.auth().onAuthStateChanged((authUser) => {
            if (authUser && !authUser.isAnonymous) {
                setUser(authUserToSVUser(authUser));
                setIsCopilot(true);
            }
            else {
                signInAudience();
            }
            if (!!authUser && authUser.displayName) {
                setTimeout(() => {
                    setShowZoomModal(true);
                }, 5000);
            }
        });
    }, []);
    react_1.useEffect(() => {
        const retrieveSession = async () => {
            const session = await sessions_1.getSession(sessionId);
            if ((session === null || session === void 0 ? void 0 : session.status) === types_1.SessionStatus.Live) {
                setSession(session);
            }
        };
        // fetch session object
        if (user) {
            retrieveSession();
        }
    }, [sessionId, user]);
    const handleSubmitName = async (name) => {
        var _a, _b;
        await ((_a = firebase_1.auth().currentUser) === null || _a === void 0 ? void 0 : _a.updateProfile({
            displayName: name,
        }));
        await ((_b = firebase_1.auth().currentUser) === null || _b === void 0 ? void 0 : _b.reload());
        const authUser = firebase_1.auth().currentUser;
        authUser && setUser(authUserToSVUser(authUser));
        setTimeout(() => {
            setShowZoomModal(true);
        }, SHOW_ZOOM_MODAL_TIMEOUT);
    };
    react_1.useEffect(() => {
        if (session && user) {
            if (session.host.id === user.id) {
                setIsCopilot(false);
            }
        }
    }, [session, user]);
    if (!session || !user) {
        return (react_1.default.createElement(react_native_1.View, { style: styles.container },
            react_1.default.createElement(react_native_1.ActivityIndicator, { animating: true })));
    }
    return (react_1.default.createElement(context_1.SessionProvider, { session: session, user: user, isCopilot: isCopilot, isHost: isHost },
        react_1.default.createElement(react_native_1.View, { style: styles.container },
            isHost ? (react_1.default.createElement(session_1.HostTopBarContainer, null)) : (react_1.default.createElement(react_native_1.View, { style: styles.topMenuContainer },
                react_1.default.createElement(_1.AudienceCounterContainer, null))),
            react_1.default.createElement(react_native_1.View, { style: styles.storyContainer },
                react_1.default.createElement(_1.StoryContainer, { story: session.story }),
                react_1.default.createElement(_1.CelebrationContainer, null)),
            react_1.default.createElement(react_native_1.View, { style: styles.bottomBarContainer },
                react_1.default.createElement(react_native_1.View, { style: styles.videoContainer },
                    react_1.default.createElement(_1.ParticipantsView, { host: session.host, user: user })),
                react_1.default.createElement(react_native_1.View, { style: styles.menuContainer }, !isHost ? (react_1.default.createElement(AudienceMenuBar_1.AudienceMenuBar, { onLike: incrementLocalHeartsCount })) : (react_1.default.createElement(session_1.HostMenuBar, null)))),
            react_1.default.createElement(questionsAndAnswers_1.QAContainer, null),
            react_1.default.createElement(_1.RandomSelectionContainer, null)),
        react_1.default.createElement(Hearts_1.HeartsContainer, { localHeartsCount: localHeartsCount }),
        react_1.default.createElement(DisplayNameModal_1.DisplayNameModal, { visible: showDisplayNamePopup, onSubmit: handleSubmitName }),
        react_1.default.createElement(components_1.ZoomModal, { visible: showZoomModal, onAccept: () => setShowZoomModal(false) })));
};
exports.AudienceSession = AudienceSession;
const styles = react_native_1.StyleSheet.create({
    bottomBarContainer: {
        alignItems: 'flex-end',
        flexDirection: 'column',
        height: '15%',
        justifyContent: 'center',
        marginTop: 20,
    },
    container: {
        height: '90vh',
        maxHeight: '90vh',
        minHeight: 600,
        minWidth: 800,
    },
    menuContainer: { height: 150, justifyContent: 'center' },
    storyContainer: { height: '85%', width: '100%' },
    topMenuContainer: { alignItems: 'flex-end' },
    videoContainer: { alignSelf: 'flex-start', flex: 1, marginLeft: 20 },
});
