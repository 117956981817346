"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToPrompt = exports.subscribeToHighlightedResponse = exports.getResponses = exports.clearHighlightedResponse = exports.setHighlightedResponse = exports.resetQuestions = exports.addResponse = exports.clearPrompt = exports.setPrompt = void 0;
const firebase_1 = require("../../firebase");
const helpers_1 = require("./helpers");
const questionsRef = (sessionId) => firebase_1.database().ref(`/sessions/${sessionId}/questions`);
const promptRef = (sessionId) => questionsRef(sessionId).child('prompt');
const responsesRef = (sessionId) => questionsRef(sessionId).child('responses');
const highlightedRespRef = (sessionId) => questionsRef(sessionId).child('highlightedResponse');
const setPrompt = async (sessionId, prompt) => {
    await promptRef(sessionId).set(prompt !== null && prompt !== void 0 ? prompt : {});
};
exports.setPrompt = setPrompt;
const clearPrompt = async (sessionId) => {
    await promptRef(sessionId).remove();
};
exports.clearPrompt = clearPrompt;
const addResponse = async (sessionId, response) => {
    await responsesRef(sessionId)
        .child(response.id)
        .set({
        ...response,
        createdAt: firebase_1.database.ServerValue.TIMESTAMP,
    });
};
exports.addResponse = addResponse;
const resetQuestions = async (sessionId) => {
    await responsesRef(sessionId).remove();
    await promptRef(sessionId).remove();
    await highlightedRespRef(sessionId).remove();
};
exports.resetQuestions = resetQuestions;
const setHighlightedResponse = async (sessionId, response) => {
    await highlightedRespRef(sessionId).set(response);
};
exports.setHighlightedResponse = setHighlightedResponse;
const clearHighlightedResponse = async (sessionId) => {
    await highlightedRespRef(sessionId).remove();
};
exports.clearHighlightedResponse = clearHighlightedResponse;
const getResponses = async (sessionId, limit = 50) => {
    const ref = responsesRef(sessionId)
        .orderByChild('createdAt')
        .limitToFirst(limit);
    return helpers_1.processSnapshotToList(await ref.once('value'));
};
exports.getResponses = getResponses;
const subscribeToHighlightedResponse = (sessionId, onHighlightedResponseChange) => {
    const ref = highlightedRespRef(sessionId);
    const onChange = (value) => onHighlightedResponseChange(helpers_1.toVal(value));
    ref.on('value', onChange);
    return () => ref.off('value', onChange);
};
exports.subscribeToHighlightedResponse = subscribeToHighlightedResponse;
const subscribeToPrompt = (sessionId, onPromptChange) => {
    const ref = promptRef(sessionId);
    const onChange = (value) => onPromptChange(helpers_1.toVal(value));
    ref.on('value', onChange);
    return () => ref.off('value', onChange);
};
exports.subscribeToPrompt = subscribeToPrompt;
