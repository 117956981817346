"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputQAModal = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const QAModal_1 = require("../QAModal");
const context_1 = require("../../../../../context");
const uiKitten_1 = require("../../../../uiKitten");
function InputQAModal({ text, isVisible, onDismiss, onSubmit, }) {
    const { translations } = context_1.useLocalizationContext();
    const [input, setInput] = react_1.useState('');
    const handleSubmit = onSubmit
        ? () => {
            onSubmit(input);
            setInput('');
        }
        : undefined;
    const handleDismiss = onDismiss
        ? () => {
            onDismiss();
            setInput('');
        }
        : undefined;
    const handleTextChange = (nextValue) => setInput(nextValue);
    return (react_1.default.createElement(QAModal_1.QAModal, { isVisible: isVisible, headerText: translations.AUDIENCE_QA, testID: "InputQAModal", onDismiss: handleDismiss },
        text ? react_1.default.createElement(uiKitten_1.Text, { style: styles.text }, text) : null,
        react_1.default.createElement(uiKitten_1.Input, { status: "primary", placeholder: translations.TYPE_QUESTION_RESPONSE, value: input, style: styles.input, onChangeText: handleTextChange, testID: "InputQAModal-Response" }),
        react_1.default.createElement(react_native_1.View, { style: styles.buttonContainer },
            react_1.default.createElement(uiKitten_1.Button, { style: styles.button, onPress: handleSubmit, testID: "InputQAModal-Send", disabled: input.length === 0 }, translations.SEND))));
}
exports.InputQAModal = InputQAModal;
const styles = react_native_1.StyleSheet.create({
    button: { minWidth: 150 },
    buttonContainer: {
        alignItems: 'center',
        paddingTop: 20,
    },
    input: { maxWidth: '100%', width: 300 },
    text: { paddingBottom: 20, textAlign: 'center' },
});
