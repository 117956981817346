"use strict";
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
const utils_1 = require("./utils");
exports.Config = {
    Storyvoice: {
        ApiUrl: (_e = (_d = (_c = (_b = (_a = utils_1.getFromCypress('API_URL')) !== null && _a !== void 0 ? _a : process.env.API_URL) !== null && _b !== void 0 ? _b : process.env.FIREBASE_API_URL) !== null && _c !== void 0 ? _c : process.env.NEXT_PUBLIC_API_URL) !== null && _d !== void 0 ? _d : process.env.STORYBOOK_API_URL) !== null && _e !== void 0 ? _e : '',
    },
    Agora: {
        AppId: (_k = (_j = (_h = (_g = (_f = utils_1.getFromCypress('AGORA_APP_ID')) !== null && _f !== void 0 ? _f : process.env.AGORA_APP_ID) !== null && _g !== void 0 ? _g : process.env.FIREBASE_AGORA_APP_ID) !== null && _h !== void 0 ? _h : process.env.NEXT_PUBLIC_AGORA_APP_ID) !== null && _j !== void 0 ? _j : process.env.STORYBOOK_AGORA_APP_ID) !== null && _k !== void 0 ? _k : '',
    },
    Sentry: {
        DSN: (_q = (_p = (_o = (_m = (_l = utils_1.getFromCypress('SENTRY_DSN')) !== null && _l !== void 0 ? _l : process.env.SENTRY_DSN) !== null && _m !== void 0 ? _m : process.env.FIREBASE_SENTRY_DSN) !== null && _o !== void 0 ? _o : process.env.NEXT_PUBLIC_SENTRY_DSN) !== null && _p !== void 0 ? _p : process.env.STORYBOOK_SENTRY_DSN) !== null && _q !== void 0 ? _q : '',
        TRACES_SAMPLE_RATE: Number((_v = (_u = (_t = (_s = (_r = utils_1.getFromCypress('SENTRY_TRACES_SAMPLE_RATE')) !== null && _r !== void 0 ? _r : process.env.SENTRY_TRACES_SAMPLE_RATE) !== null && _s !== void 0 ? _s : process.env.FIREBASE_SENTRY_TRACES_SAMPLE_RATE) !== null && _t !== void 0 ? _t : process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) !== null && _u !== void 0 ? _u : process.env.STORYBOOK_SENTRY_TRACES_SAMPLE_RATE) !== null && _v !== void 0 ? _v : 0.2),
    },
    Firebase: {
        ApiKey: (_0 = (_z = (_y = (_x = (_w = utils_1.getFromCypress('API_KEY')) !== null && _w !== void 0 ? _w : process.env.API_KEY) !== null && _x !== void 0 ? _x : process.env.FIREBASE_API_KEY) !== null && _y !== void 0 ? _y : process.env.NEXT_PUBLIC_FIREBASE_API_KEY) !== null && _z !== void 0 ? _z : process.env.STORYBOOK_FIREBASE_API_KEY) !== null && _0 !== void 0 ? _0 : '',
        AuthDomain: (_5 = (_4 = (_3 = (_2 = (_1 = utils_1.getFromCypress('AUTH_DOMAIN')) !== null && _1 !== void 0 ? _1 : process.env.AUTH_DOMAIN) !== null && _2 !== void 0 ? _2 : process.env.FIREBASE_AUTH_DOMAIN) !== null && _3 !== void 0 ? _3 : process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN) !== null && _4 !== void 0 ? _4 : process.env.STORYBOOK_FIREBASE_AUTH_DOMAIN) !== null && _5 !== void 0 ? _5 : '',
        DatabaseURL: (_10 = (_9 = (_8 = (_7 = (_6 = utils_1.getFromCypress('DATABASE_URL')) !== null && _6 !== void 0 ? _6 : process.env.DATABASE_URL) !== null && _7 !== void 0 ? _7 : process.env.FIREBASE_DATABASE_URL) !== null && _8 !== void 0 ? _8 : process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL) !== null && _9 !== void 0 ? _9 : process.env.STORYBOOK_FIREBASE_DATABASE_URL) !== null && _10 !== void 0 ? _10 : '',
        ProjectId: (_15 = (_14 = (_13 = (_12 = (_11 = utils_1.getFromCypress('PROJECT_ID')) !== null && _11 !== void 0 ? _11 : process.env.PROJECT_ID) !== null && _12 !== void 0 ? _12 : process.env.FIREBASE_PROJECT_ID) !== null && _13 !== void 0 ? _13 : process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID) !== null && _14 !== void 0 ? _14 : process.env.STORYBOOK_FIREBASE_PROJECT_ID) !== null && _15 !== void 0 ? _15 : '',
        StorageBucket: (_20 = (_19 = (_18 = (_17 = (_16 = utils_1.getFromCypress('STORAGE_BUCKET')) !== null && _16 !== void 0 ? _16 : process.env.STORAGE_BUCKET) !== null && _17 !== void 0 ? _17 : process.env.FIREBASE_STORAGE_BUCKET) !== null && _18 !== void 0 ? _18 : process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET) !== null && _19 !== void 0 ? _19 : process.env.STORYBOOK_FIREBASE_STORAGE_BUCKET) !== null && _20 !== void 0 ? _20 : '',
        MessagingSenderId: (_25 = (_24 = (_23 = (_22 = (_21 = utils_1.getFromCypress('MESSAGING_SENDER_ID')) !== null && _21 !== void 0 ? _21 : process.env.MESSAGING_SENDER_ID) !== null && _22 !== void 0 ? _22 : process.env.FIREBASE_MESSAGING_SENDER_ID) !== null && _23 !== void 0 ? _23 : process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID) !== null && _24 !== void 0 ? _24 : process.env.STORYBOOK_MESSAGING_SENDER_ID) !== null && _25 !== void 0 ? _25 : '',
        AppId: (_29 = (_28 = (_27 = (_26 = utils_1.getFromCypress('FIREBASE_APP_ID')) !== null && _26 !== void 0 ? _26 : process.env.FIREBASE_APP_ID) !== null && _27 !== void 0 ? _27 : process.env.NEXT_PUBLIC_FIREBASE_APP_ID) !== null && _28 !== void 0 ? _28 : process.env.STORYBOOK_FIREBASE_APP_ID) !== null && _29 !== void 0 ? _29 : '',
        MeasurementId: (_34 = (_33 = (_32 = (_31 = (_30 = utils_1.getFromCypress('MEASUREMENT_ID')) !== null && _30 !== void 0 ? _30 : process.env.MEASUREMENT_ID) !== null && _31 !== void 0 ? _31 : process.env.FIREBASE_MEASUREMENT_ID) !== null && _32 !== void 0 ? _32 : process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID) !== null && _33 !== void 0 ? _33 : process.env.STORYBOOK_FIREBASE_MEASUREMENT_ID) !== null && _34 !== void 0 ? _34 : '',
        enableEmulators: ((_39 = (_38 = (_37 = (_36 = (_35 = utils_1.getFromCypress('ENABLE_EMULATORS')) !== null && _35 !== void 0 ? _35 : process.env.ENABLE_EMULATORS) !== null && _36 !== void 0 ? _36 : process.env.FIREBASE_ENABLE_EMULATORS) !== null && _37 !== void 0 ? _37 : process.env.NEXT_PUBLIC_FIREBASE_ENABLE_EMULATORS) !== null && _38 !== void 0 ? _38 : process.env.STORYBOOK_FIREBASE_ENABLE_EMULATORS) !== null && _39 !== void 0 ? _39 : 'false') === 'true',
        enableAnalytics: ((_44 = (_43 = (_42 = (_41 = (_40 = utils_1.getFromCypress('ENABLE_ANALYTICS')) !== null && _40 !== void 0 ? _40 : process.env.ENABLE_ANALYTICS) !== null && _41 !== void 0 ? _41 : process.env.FIREBASE_ENABLE_ANALYTICS) !== null && _42 !== void 0 ? _42 : process.env.NEXT_PUBLIC_FIREBASE_ENABLE_ANALYTICS) !== null && _43 !== void 0 ? _43 : process.env.STORYBOOK_FIREBASE_ENABLE_ANALYTICS) !== null && _44 !== void 0 ? _44 : 'false') === 'true',
        disableAgora: ((_48 = (_47 = (_46 = (_45 = utils_1.getFromCypress('DISABLE_AGORA')) !== null && _45 !== void 0 ? _45 : process.env.DISABLE_AGORA) !== null && _46 !== void 0 ? _46 : process.env.NEXT_PUBLIC_DISABLE_AGORA) !== null && _47 !== void 0 ? _47 : process.env.STORYBOOK_DISABLE_AGORA) !== null && _48 !== void 0 ? _48 : 'false') === 'true',
    },
};
