"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toVal = exports.processSnapshotToList = void 0;
function processSnapshotToList(value) {
    const val = value.val();
    if (val instanceof Array) {
        return val;
    }
    else if (val instanceof Object) {
        return Object.values(val);
    }
    return [];
}
exports.processSnapshotToList = processSnapshotToList;
function toVal(value) {
    return value.val();
}
exports.toVal = toVal;
