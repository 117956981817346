"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HostQAOverlay = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const react_native_super_grid_1 = require("react-native-super-grid");
const uiKitten_1 = require("../../../uiKitten");
const HostQAOverlayHeader_1 = require("./HostQAOverlayHeader");
const HostQACollectResponses_1 = require("./HostQACollectResponses");
const HostQACollecting_1 = require("./HostQACollecting");
function HostQAOverlay({ isVisible, onResetQuestions, items = [], onPressItem, onCollectResponses, activePrompt, }) {
    const styles = uiKitten_1.useStyleSheet(themedStyles);
    const [displayedItems, setDisplayedItems] = react_1.useState([]);
    const handleRenderItem = ({ item }) => {
        const handlePressItem = () => {
            setDisplayedItems([...displayedItems, item]);
            onPressItem && onPressItem(item);
        };
        return (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: handlePressItem, style: [
                styles.itemContainer,
                displayedItems.find((p) => p.id === item.id) && styles.selectedItem,
            ] },
            react_1.default.createElement(uiKitten_1.Text, { category: "c1", numberOfLines: 2, style: styles.itemText }, `${item.fullName} (${item.country})`),
            react_1.default.createElement(uiKitten_1.Text, { category: "c2", numberOfLines: 2 }, item.response)));
    };
    if (!isVisible) {
        return null;
    }
    const handleCollectResponses = (text) => {
        onCollectResponses && onCollectResponses(text);
    };
    const handleClearList = () => {
        onResetQuestions && onResetQuestions();
        setDisplayedItems([]);
    };
    const emptyComponent = activePrompt ? (react_1.default.createElement(HostQACollecting_1.HostQACollecting, null)) : (react_1.default.createElement(HostQACollectResponses_1.HostQACollectResponses, { onCollectResponses: handleCollectResponses }));
    return (react_1.default.createElement(react_native_super_grid_1.FlatGrid, { ListEmptyComponent: emptyComponent, itemDimension: 150, stickyHeaderIndices: [0], data: items, centerContent: true, style: styles.grid, ListHeaderComponent: react_1.default.createElement(HostQAOverlayHeader_1.HostQAOverlayHeader, { onClearList: handleClearList, subHeaderText: activePrompt ? `Active Prompt: ${activePrompt.text}` : '' }), contentContainerStyle: styles.contentContainer, renderItem: handleRenderItem }));
}
exports.HostQAOverlay = HostQAOverlay;
const themedStyles = uiKitten_1.StyleService.create({
    button: { minWidth: 150 },
    buttonContainer: {
        alignItems: 'center',
        paddingTop: 20,
    },
    contentContainer: { flexGrow: 1, paddingBottom: 150, paddingTop: 0 },
    grid: {
        backgroundColor: 'color-basic-100',
        paddingTop: 0,
        width: '100%',
    },
    itemContainer: {
        borderColor: 'color-basic-400',
        borderRadius: 12,
        borderWidth: 1,
        marginHorizontal: 10,
        marginTop: 10,
        minHeight: 107,
        padding: 20,
    },
    itemText: {
        marginBottom: 15,
    },
    selectedItem: {
        backgroundColor: 'color-basic-400',
    },
    text: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
});
