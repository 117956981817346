"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageColumn = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const shared_1 = require("./shared");
const constants_1 = require("../../../../constants");
function getImageDim({ originalImageDim, containerDim, isPortrait, }) {
    const { width: columnWidth, height: columnHeight } = containerDim;
    const { width, height } = originalImageDim;
    let imageDim = { width: 0, height: 0 };
    let scaleFactor;
    const aspectRatio = width / height;
    if (width >= height || isPortrait) {
        scaleFactor = columnWidth / width;
        const scaledHeight = height * scaleFactor;
        const h = Math.min(scaledHeight, columnHeight);
        const w = Math.min(h * aspectRatio, columnWidth);
        imageDim = {
            width: w,
            height: h,
        };
    }
    else {
        scaleFactor = columnHeight / height;
        const scaledWidth = width * scaleFactor;
        imageDim = {
            width: Math.min(scaledWidth, columnWidth),
            height: columnHeight,
        };
    }
    return imageDim;
}
const ImageColumn = ({ imageSource, isLeft = false, isPortrait, }) => {
    const [originalImageDim, setOriginalImageDim] = react_1.useState({
        width: 0,
        height: 0,
    });
    const [containerDim, setContainerDim] = react_1.useState({ width: 0, height: 0 });
    const [imageSize, setImageSize] = react_1.useState({ width: 0, height: 0 });
    const [loading, setLoading] = react_1.useState(true);
    const [loadingLayout, setLoadingLayout] = react_1.useState(true);
    react_1.useEffect(() => {
        setLoadingLayout(true);
    }, [isPortrait]);
    const componentIsMounted = react_1.useRef(true);
    react_1.useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);
    react_1.useEffect(() => {
        if (originalImageDim && containerDim) {
            setImageSize(getImageDim({ originalImageDim, containerDim, isPortrait }));
        }
    }, [originalImageDim, containerDim, isPortrait]);
    const onImageLoad = react_1.useCallback(() => {
        imageSource.uri &&
            react_native_1.Image.getSize(imageSource.uri, (width, height) => {
                setLoading(false);
                setOriginalImageDim({
                    width,
                    height,
                });
            });
    }, [imageSource.uri]);
    const onLayout = react_1.useCallback((event) => {
        if (!componentIsMounted.current) {
            return;
        }
        setLoadingLayout(false);
        setContainerDim({
            width: event.nativeEvent.layout.width,
            height: event.nativeEvent.layout.height,
        });
    }, []);
    return (react_1.default.createElement(react_native_1.View, { style: [
            shared_1.columnStyles.column,
            styles.imageColumnContainer,
            !isLeft && styles.rightColumn,
        ], onLayout: onLayout },
        (loading || loadingLayout) && (react_1.default.createElement(react_native_1.View, { style: styles.imageLoadingOverlay },
            react_1.default.createElement(react_native_1.ActivityIndicator, { size: "large" }))),
        !isLeft && react_1.default.createElement(react_native_1.View, { style: styles.imageColumnOverlay }),
        react_1.default.createElement(react_native_1.Image, { source: imageSource, style: [
                {
                    ...imageSize,
                },
                isPortrait
                    ? {
                        alignSelf: 'center',
                    }
                    : {
                        alignSelf: isLeft ? 'flex-end' : 'flex-start',
                    },
            ], resizeMode: "contain", onLoad: onImageLoad })));
};
exports.ImageColumn = ImageColumn;
const styles = react_native_1.StyleSheet.create({
    imageColumnContainer: {
        alignSelf: 'center',
        justifyContent: 'center',
    },
    imageColumnOverlay: {
        backgroundColor: constants_1.Colors.imageColumnOverlayColor,
        height: '100%',
        position: 'absolute',
        width: '100%',
        zIndex: 1,
    },
    imageLoadingOverlay: {
        backgroundColor: constants_1.Colors.imageLoadingOverlayColor,
        bottom: 0,
        flex: 1,
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
    },
    rightColumn: {
        marginLeft: -1,
    },
});
