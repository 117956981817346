"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSessionCTA = void 0;
const react_1 = require("react");
const models_1 = require("../../models");
const defaultCTA = {
    title: `Thanks for tuning in! We'd love to hear your feedback. Takes less than a minute to complete.🕺💃`,
    button: {
        text: 'Offer Feedback',
        link: 'https://storyvoice.live/session-feedback',
        status: 'primary',
    },
};
// TODO: use session context here for sessionId when mobile is DRYed up
function useSessionCTA({ sessionId, }) {
    const [sessionCTA, setSessionCTA] = react_1.useState();
    react_1.useEffect(() => {
        (async () => {
            var _a;
            const cta = (_a = (await models_1.getSession(sessionId))) === null || _a === void 0 ? void 0 : _a.cta;
            setSessionCTA(cta || defaultCTA);
        })();
    }, [sessionId]);
    return sessionCTA;
}
exports.useSessionCTA = useSessionCTA;
