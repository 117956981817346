"use strict";
/* global Cypress */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFromCypress = void 0;
// Generates environment variable names to lookup in order of precedence.
const genNames = (name) => {
    return [name, `FIREBASE_${name}`, `NEXT_PUBLIC_${name}`, `STORYBOOK_${name}`];
};
const getFromCypress = (name) => {
    if (typeof Cypress === 'undefined') {
        return;
    }
    for (const n of genNames(name)) {
        const value = Cypress.env(n);
        if (value) {
            return value;
        }
    }
    return;
};
exports.getFromCypress = getFromCypress;
