"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AspectView = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
/**
 * Web/mobile workaround for aspect ratio
 * https://baconbrix.gitbook.io/react-native-web/styling/aspect-ratio
 */
function AspectView(props) {
    const [layout, setLayout] = react_1.useState(null);
    const { aspectRatio = 1, ...inputStyle } = react_native_1.StyleSheet.flatten(props.style) || {};
    const style = [inputStyle, { aspectRatio }];
    if (layout) {
        const { width = 0, height = 0 } = layout;
        if (width === 0) {
            style.push({ width: height / aspectRatio, height });
        }
        else {
            style.push({ width, height: width / aspectRatio });
        }
    }
    return (react_1.default.createElement(react_native_1.View, { ...props, style: style, onLayout: ({ nativeEvent: { layout } }) => setLayout(layout) }));
}
exports.AspectView = AspectView;
