"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteStory = exports.getStreamStories = exports.getPublicStories = exports.processStorySnapshot = exports.getStory = void 0;
const firebase_1 = require("../firebase");
const storiesConverter = {
    toFirestore(story) {
        return story;
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return {
            id: snapshot.id,
            title: data === null || data === void 0 ? void 0 : data.title,
            imageUrl: data === null || data === void 0 ? void 0 : data.imageUrl,
            body: data === null || data === void 0 ? void 0 : data.body,
            streamCode: data === null || data === void 0 ? void 0 : data.streamCode,
            pictureBook: data === null || data === void 0 ? void 0 : data.pictureBook,
            inLibrary: data === null || data === void 0 ? void 0 : data.inLibrary,
            useDisplayAsCover: data === null || data === void 0 ? void 0 : data.useDisplayAsCover,
        };
    },
};
const storiesCollection = firebase_1.firestore().collection('stories');
const getStory = async (storyId) => {
    return storiesConverter.fromFirestore(await storiesCollection.doc(storyId).get());
};
exports.getStory = getStory;
const processStorySnapshot = (querySnapshot) => {
    const stories = [];
    querySnapshot.forEach((documentSnapshot) => stories.push(storiesConverter.fromFirestore(documentSnapshot)));
    return stories;
};
exports.processStorySnapshot = processStorySnapshot;
const getPublicStories = async () => {
    const snapshot = await storiesCollection
        .where('streamCode', '==', 'public')
        .where('inLibrary', '==', true)
        .orderBy('title', 'asc')
        .get();
    return exports.processStorySnapshot(snapshot);
};
exports.getPublicStories = getPublicStories;
const getStreamStories = async ({ streamCode, }) => {
    return exports.processStorySnapshot(await storiesCollection
        .where('streamCode', '==', streamCode)
        .orderBy('title', 'asc')
        .get());
};
exports.getStreamStories = getStreamStories;
const deleteStory = async (id) => {
    await storiesCollection.doc(id).delete();
};
exports.deleteStory = deleteStory;
