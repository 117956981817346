"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCopilots = void 0;
const react_1 = require("react");
const context_1 = require("../../../context");
const utils_1 = require("../../utils");
/**
 * Retrieves the session copilots list every 3 seconds. Must be child of SessionProvider.
 */
const useCopilots = () => {
    const [copilots, setCopilots] = react_1.useState([]);
    const { sessionHostActions } = context_1.useSessionContext();
    utils_1.useInterval(async () => {
        var _a;
        setCopilots((_a = (await (sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.getCopilots()))) !== null && _a !== void 0 ? _a : []);
    }, 3000);
    return copilots;
};
exports.useCopilots = useCopilots;
