"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStreamCodeById = void 0;
const firebase_1 = require("../firebase");
const usersCollection = firebase_1.firestore().collection('users');
// TODO: add converter
/**
 * Retrieve stream code of the users by id.
 */
const getStreamCodeById = async (id) => {
    var _a;
    const userSnapshot = await usersCollection.doc(id).get();
    if (!userSnapshot.exists) {
        return null;
    }
    return (_a = userSnapshot.data()) === null || _a === void 0 ? void 0 : _a.streamCode;
};
exports.getStreamCodeById = getStreamCodeById;
