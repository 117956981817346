"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopilotInvitationStatus = void 0;
/**
 * @category Realtime
 */
var CopilotInvitationStatus;
(function (CopilotInvitationStatus) {
    CopilotInvitationStatus["Invited"] = "invited";
    CopilotInvitationStatus["Accepted"] = "accepted";
    CopilotInvitationStatus["Dismissed"] = "dismissed";
})(CopilotInvitationStatus = exports.CopilotInvitationStatus || (exports.CopilotInvitationStatus = {}));
