"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipationCheck = void 0;
const react_1 = __importStar(require("react"));
const useFirebaseCheck_1 = require("../../../../hooks/checks/useFirebaseCheck");
const CheckWithSpinner_1 = require("./common/CheckWithSpinner");
function ParticipationCheck({ onComplete, }) {
    const { result } = useFirebaseCheck_1.useFirebaseCheck();
    react_1.useEffect(() => {
        result && onComplete(result);
    }, [result, onComplete]);
    return (react_1.default.createElement(CheckWithSpinner_1.CheckWithSpinner, { title: "Participation Check", description: 'We are checking if you can join and participate in Storyvoice sessions. Please wait while we run this check.' }));
}
exports.ParticipationCheck = ParticipationCheck;
