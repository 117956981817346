"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAudience = void 0;
const react_1 = require("react");
const context_1 = require("../../../context");
const utils_1 = require("../../utils");
/**
 * Retrieves the session audience list every 3 seconds. Must be child of SessionProvider.
 */
const useAudience = () => {
    const [audience, setAudience] = react_1.useState([]);
    const { sessionHostActions } = context_1.useSessionContext();
    utils_1.useInterval(async () => {
        var _a;
        setAudience((_a = (await (sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.getAudience()))) !== null && _a !== void 0 ? _a : []);
    }, 3000);
    return audience;
};
exports.useAudience = useAudience;
