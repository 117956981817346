"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirebaseCheck = void 0;
const react_1 = require("react");
const types_1 = require("../../types");
const firebase_1 = require("../../firebase");
const models_1 = require("../../models");
const TEST_SESSION_ID = '0Bhjao2MA5HhO0Zs5Guo';
const checkFirebaseAuth = async () => {
    try {
        if (!firebase_1.auth().currentUser) {
            await firebase_1.auth().signInAnonymously();
        }
        return { passed: true };
    }
    catch (e) {
        return { passed: false, errors: [e.message] };
    }
};
const checkFirestore = async () => {
    try {
        if ((await models_1.getSession(TEST_SESSION_ID)) !== undefined) {
            return { passed: true };
        }
        else {
            return {
                passed: false,
                errors: [`Firestore couldn't retrieve session id (${TEST_SESSION_ID})`],
            };
        }
    }
    catch (e) {
        return { passed: false, errors: [e.message] };
    }
};
const checkRTDB = async () => {
    try {
        if ((await models_1.getSessionLikesCount(TEST_SESSION_ID)) !== null) {
            return { passed: true };
        }
        else {
            return {
                passed: false,
                errors: [
                    `Realtime Database couldn't retrieve session likes of session id (${TEST_SESSION_ID})`,
                ],
            };
        }
    }
    catch (e) {
        return { passed: false, errors: [e.message] };
    }
};
function useFirebaseCheck() {
    const [result, setResult] = react_1.useState();
    const [authResult, setAuthResult] = react_1.useState();
    const [rtdbResult, setRtdbResult] = react_1.useState();
    const [firestoreResult, setFirestoreResult] = react_1.useState();
    const [status, setStatus] = react_1.useState(types_1.CheckStatus.InProgress);
    react_1.useEffect(() => {
        (async () => {
            setAuthResult(await checkFirebaseAuth());
            setFirestoreResult(await checkFirestore());
            setRtdbResult(await checkRTDB());
            setStatus(types_1.CheckStatus.Completed);
        })();
    }, []);
    react_1.useEffect(() => {
        if (status === types_1.CheckStatus.Completed) {
            authResult &&
                firestoreResult &&
                rtdbResult &&
                setResult({
                    auth: authResult,
                    firestore: firestoreResult,
                    rtdb: rtdbResult,
                });
        }
    }, [status, authResult, firestoreResult, rtdbResult]);
    return { result };
}
exports.useFirebaseCheck = useFirebaseCheck;
