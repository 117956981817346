"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Emojis = exports.Gifs = exports.Images = exports.Backgrounds = exports.Icons = void 0;
exports.Icons = {
    handActive: require('../../assets/images/hand_active.png'),
    handInactive: require('../../assets/images/hand_inactive.png'),
    logo: require('../../assets/images/icon_logo.png'),
    textLogo: require('../../assets/images/storyvoice-logo.png'),
    earthTextLogo: require('../../assets/images/logo-text-earth.png'),
    fontSizeChange: require('../../assets/images/font_size_change.png'),
};
exports.Backgrounds = {
    nightBackground: require('../../assets/images/background.png'),
    animatingBackground: require('../../assets/images/stars_background.gif'),
    bottomCircle: require('../../assets/images/bottom_circle.png'),
    rainbowBackground: require('../../assets/images/rainbow_background.jpg'),
    kidsElephantBackground: require('../../assets/images/kids-elephant.png'),
    earthReadingBackground: require('../../assets/images/earth-reading.png'),
    readAloudHeaderBackground: require('../../assets/images/world-read-aloud-header.png'),
};
exports.Images = {
    kidRaisingHand: require('../../assets/images/kidraisinghand-01.png'),
    kidWithStars: require('../../assets/images/kid_with_stars.jpg'),
    girlWithAlphabet: require('../../assets/images/girl_with_alphabet.jpg'),
    mikePressable: require('../../assets/images/mike_pressable.png'),
    heart: require('../../assets/images/heart.png'),
    girlWaitingByWindow: require('../../assets/images/girl-waiting-by-window.png'),
    cardPlaceholder: require('../../assets/images/placeholder-card.png'),
    appScreenshot: require('../../assets/images/mike-peter.png'),
    laptopScreenshot: require('../../assets/images/laptop.jpg'),
};
exports.Gifs = {
    fireworks: require('../../assets/images/fireworks_celeb.gif'),
    earth: require('../../assets/images/earth.gif'),
    rotateToLandscape: require('../../assets/images/gifs/toHorizontal.gif'),
    rotateToPortrait: require('../../assets/images/gifs/toVertical.gif'),
    pinchToZoom: require('../../assets/images/gifs/pinch-to-zoom.gif'),
    doubleClickToZoom: require('../../assets/images/gifs/double-click.gif'),
};
exports.Emojis = {
    raisedHand: require('../../assets/images/emojis/hand.png'),
    heart: require('../../assets/images/emojis/heart.png'),
    tada: require('../../assets/images/emojis/tada.png'),
    openBook: require('../../assets/images/emojis/book.png'),
    closedBook: require('../../assets/images/emojis/closed_book.png'),
    plus: require('../../assets/images/emojis/plus.png'),
    cross: require('../../assets/images/emojis/cross.png'),
    magnifier: require('../../assets/images/emojis/mag.png'),
};
