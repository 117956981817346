"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeSizeOptions = exports.ThemeSize = exports.ThemeAppearanceOptions = exports.ThemeAppearance = exports.ThemeStatusOptions = exports.ThemeStatus = void 0;
var ThemeStatus;
(function (ThemeStatus) {
    ThemeStatus["Basic"] = "basic";
    ThemeStatus["Primary"] = "primary";
    ThemeStatus["Success"] = "success";
    ThemeStatus["Info"] = "info";
    ThemeStatus["Warning"] = "warning";
    ThemeStatus["Danger"] = "danger";
    ThemeStatus["Control"] = "control";
})(ThemeStatus = exports.ThemeStatus || (exports.ThemeStatus = {}));
exports.ThemeStatusOptions = [
    ...Object.values(ThemeStatus).filter((k) => typeof k === 'string'),
];
var ThemeAppearance;
(function (ThemeAppearance) {
    ThemeAppearance["Filled"] = "filled";
    ThemeAppearance["Outline"] = "outline";
    ThemeAppearance["Ghost"] = "ghost";
})(ThemeAppearance = exports.ThemeAppearance || (exports.ThemeAppearance = {}));
exports.ThemeAppearanceOptions = [
    ...Object.values(ThemeAppearance).filter((k) => typeof k === 'string'),
];
var ThemeSize;
(function (ThemeSize) {
    ThemeSize["Tiny"] = "tiny";
    ThemeSize["Small"] = "small";
    ThemeSize["Medium"] = "medium";
    ThemeSize["Large"] = "large";
    ThemeSize["Giant"] = "giant";
})(ThemeSize = exports.ThemeSize || (exports.ThemeSize = {}));
exports.ThemeSizeOptions = [
    ...Object.values(ThemeSize).filter((k) => typeof k === 'string'),
];
