"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RandomSelection = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const Animatable = __importStar(require("react-native-animatable"));
const constants_1 = require("../../../constants");
const InteractivePopup_1 = require("../InteractivePopup");
const uiKitten_1 = require("../../uiKitten");
const RandomSelection = ({ names, visible = false, onEnd, }) => {
    const [showBackground, setShowBackground] = react_1.useState(false);
    const DURATION = 2000;
    const DELAY_FACTOR = DURATION / names.length;
    react_1.useEffect(() => {
        setShowBackground(false);
    }, [visible]);
    const onAnimationEnd = () => {
        setShowBackground(true);
        onEnd && onEnd();
    };
    return (react_1.default.createElement(InteractivePopup_1.InteractivePopup, { isVisible: visible, containerStyle: { padding: 30, height: 150 } },
        react_1.default.createElement(uiKitten_1.Text, { category: "h6", style: styles.headerText }, "Our next reader is"),
        react_1.default.createElement(react_native_1.View, { style: [styles.nameBox, showBackground && styles.whiteBorder] },
            react_1.default.createElement(react_native_1.ImageBackground, { source: showBackground ? constants_1.Backgrounds.rainbowBackground : {}, style: styles.imageBackground, resizeMode: "cover" }, names.map((name, i) => (react_1.default.createElement(Animatable.Text, { key: i, duration: DURATION, delay: i * DELAY_FACTOR + i * 100, style: [styles.text, showBackground && styles.whiteText], animation: {
                    from: { translateY: -80 },
                    // last name should be visible inside the box
                    to: { translateY: i === names.length - 1 ? 0 : 80 },
                }, direction: "normal", useNativeDriver: true, onAnimationEnd: i === names.length - 1 ? onAnimationEnd : undefined }, name)))))));
};
exports.RandomSelection = RandomSelection;
const styles = react_native_1.StyleSheet.create({
    headerText: {
        fontSize: 15,
    },
    imageBackground: {
        alignItems: 'center',
        height: 100,
        padding: 10,
        width: 300,
    },
    nameBox: {
        alignItems: 'center',
        borderColor: constants_1.Colors.gray,
        borderRadius: 20,
        borderWidth: 1,
        height: 40,
        marginTop: 15,
        overflow: 'hidden',
        width: '100%',
    },
    text: {
        fontWeight: 'bold',
        position: 'absolute',
        top: 10,
    },
    whiteBorder: {
        borderColor: constants_1.Colors.white,
    },
    whiteText: {
        color: constants_1.Colors.white,
    },
});
