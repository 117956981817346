"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalizationContext = exports.LocalizationProvider = exports.LocalizationContext = void 0;
const react_1 = __importStar(require("react"));
const RNLocalize = __importStar(require("react-native-localize"));
const translations_1 = __importStar(require("./translations"));
exports.LocalizationContext = react_1.createContext({
    translations: translations_1.default,
    appLanguage: translations_1.DEFAULT_LANGUAGE,
    initializeAppLanguage: () => undefined,
    setLanguage: () => undefined,
});
const LocalizationProvider = ({ children, }) => {
    const [appLanguage, setAppLanguage] = react_1.useState(translations_1.DEFAULT_LANGUAGE);
    const setLanguage = (language) => {
        translations_1.default.setLanguage(language);
        setAppLanguage(language);
    };
    const initializeAppLanguage = async () => {
        let localeCode = translations_1.DEFAULT_LANGUAGE;
        const supportedLocaleCodes = translations_1.default.getAvailableLanguages();
        const phoneLocaleCodes = RNLocalize.getLocales().map((locale) => locale.languageCode);
        phoneLocaleCodes.some((code) => {
            if (supportedLocaleCodes.includes(code)) {
                localeCode = code;
                return true;
            }
            return false;
        });
        setLanguage(localeCode);
    };
    return (react_1.default.createElement(exports.LocalizationContext.Provider, { value: {
            translations: translations_1.default,
            appLanguage,
            initializeAppLanguage,
            setLanguage,
        } }, children));
};
exports.LocalizationProvider = LocalizationProvider;
const useLocalizationContext = () => {
    const context = react_1.useContext(exports.LocalizationContext);
    if (!context) {
        throw new Error('useLocalizationContext must be used within a LocalizationProvider');
    }
    return context;
};
exports.useLocalizationContext = useLocalizationContext;
