"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoryContainer = void 0;
const react_1 = __importStar(require("react"));
const components_1 = require("../components");
const context_1 = require("../context");
const models_1 = require("../models");
const StoryContainer = ({ story }) => {
    const [hostPage, setHostPage] = react_1.useState();
    const [hostLock, setHostLock] = react_1.useState();
    const [hostIsPortrait, setHostIsPortrait] = react_1.useState();
    const [storyIsPortrait, setStoryIsPortrait] = react_1.useState(false);
    const { session, sessionListeners, isHost } = context_1.useSessionContext();
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.hostPage((hostPage) => {
            if (hostPage) {
                setHostPage(hostPage.page);
            }
        });
    }, [sessionListeners, hostLock]);
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.hostLock((hostLock) => {
            var _a;
            setHostLock((_a = hostLock === null || hostLock === void 0 ? void 0 : hostLock.enabled) !== null && _a !== void 0 ? _a : false);
        });
    }, [sessionListeners]);
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.hostOrientation((hostOrientation) => {
            hostOrientation
                ? setHostIsPortrait(hostOrientation.isPortrait)
                : setHostIsPortrait(false);
        });
    }, [sessionListeners, hostLock]);
    react_1.useEffect(() => {
        if (hostLock) {
            setStoryIsPortrait(hostIsPortrait !== null && hostIsPortrait !== void 0 ? hostIsPortrait : false);
        }
    }, [hostLock, hostIsPortrait]);
    const onPageChangeCallback = isHost
        ? (page) => {
            session && models_1.setCurrentPage(session === null || session === void 0 ? void 0 : session.id, page);
        }
        : undefined;
    return (react_1.default.createElement(components_1.Story, { goToPage: hostLock ? hostPage : undefined, story: story, isPortrait: storyIsPortrait, scrollingLocked: hostLock, showControls: !hostLock || isHost, onPageChangeCallback: onPageChangeCallback }));
};
exports.StoryContainer = StoryContainer;
