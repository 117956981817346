"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const strings = {
    en: {
        WELCOME_STORYVOICE: 'Welcome to storyvoice!',
        RECEIVE_NOTIFICATION: 'You will receive a notification when the session begins, and can join automatically from this page.',
        ABOUT: 'About',
        SETTINGS: 'Settings',
        READER_LOGIN: 'Reader Login',
        READER: 'Reader',
        YOU_ARE_LIVE: 'You are live',
        EXIT_SESSION: 'Exit session',
        CANCEL: 'Cancel',
        RAISE_HAND_INFO_TEXT: 'You have selected to raise your hand! If you press Raise Hand, the reader can now invite you to practice ' +
            'reading live with them. Everyone will hear you and the reader once you are live.',
        RAISE_HAND_PERMISSIONS_TEXT: 'After raising your hand, your teacher can call on you. Please give us access to your ' +
            'microphone so you can speak with your teacher on the app.',
        RAISE_HAND_PERMISSIONS_ANDROID_TEXT: 'Please give us access to your microphone and camera so you can participate in the session. Only your voice will be used.',
        ALLOW_ACCESS_MICROPHONE: 'Allow access to microphone',
        ALLOW_ACCESS_MICROPHONE_CAMERA: 'Allow access to microphone and camera',
        RAISE_HAND: 'RAISE HAND',
        WHAT_IS_YOUR_NAME: 'Welcome to storyvoice!',
        ENTER_NAME_HERE: 'Enter your name',
        LETS_GO: "Let's go!",
        BELOW_ACTIVE_SESSIONS: 'Below are the active live sessions.',
        PRESS_TO_JOIN: 'Press on one to join.',
        WHERE_STORYTELLERS: 'Where storytellers from around the world read live stories every night!',
        READING_SCHEDULE: 'Public reading schedule:',
        CLASSROOM_LOGIN: 'Stream Login',
        GET_STARTED: 'To get started, please enter your first name below.',
        FIRST_NAME: 'First Name',
        CHECK_BACK: 'Check back for the upcoming sessions day and time.',
        TODAY: 'Today',
        TOMORROW: 'Tomorrow',
        AT: '@',
        HAVE_CLASSROOM_CODE: 'Have a stream code? Enter it here.',
        AVAILABLE_READING_STREAMS_CLASSROOM: 'Available live reading streams will appear here once your teacher begins their session.',
        MAY_STORIES: 'May the stories be with you!',
        SUCCESSFULLY_JOINED_CLASSROOM: 'You have successfully joined your classroom stream.',
        CHECK_INTERNET_CONNECTION: 'Check your internet connection and try again.',
        PERMISSION_UNAVAILABLE_ERROR: "We couldn't request the required permissions. If you think this is an issue, please contact us @ support@storyvoice.live",
        PERMISSION_GENERAL_ERROR: 'An error has occurred while trying to request the required permissions. Please try again.',
        CHECK_CONNECTION_TRY_AGAIN: 'Please check your internet connection and press on the button below.',
        RECONNECT: 'Reconnect',
        WE_READ: 'We read English children’s stories every Monday, Wednesday, and Friday. You will recieve a notification when the next live session begins. See you soon!',
        NEXT_SESSION_STARTS: 'The next reading session will start:',
        SESSION_TODAY_BEGINS_SHORTLY: 'Today’s session will begin shortly...',
        STORYTELLING_EXPERIENCES: 'Read stories and practice languages with storytellers from around the world. ',
        START_READING: 'Start reading!',
        READ_WITH_WORLD: 'Live storytelling for kids everywhere ',
        LOADING: 'Loading...',
        SESSION_STARTING_SOON: 'The session is starting soon!',
        UPDATE_REQUIRED_TITLE: 'Update Required',
        UPDATE_REQUIRED_TEXT: 'Please update Storyvoice to continue using the app.',
        UPDATE_REQUIRED_BUTTON: 'Update',
        PRIVACY_POLICY: 'Privacy Policy',
        TERMS: 'Terms of Service',
        BY_CONTINUING: "By continuing, you agree to Storyvoice's",
        AND: 'and',
        PERMISSIONS_REQUIRED_TO_START_SESSION: 'To go live, please provide Storyvoice with both microphone and camera permissions.',
        PLEASE_ROTATE: 'Please rotate your device',
        THIS_ENABLED_BEST_VIEWING_EXPERIENCE: 'This enables the best viewing experience for this story.',
        COPILOT_MODAL_HEADER_TEXT: 'Hey Co-Pilot! The host has invited you to join the live session.',
        COPILOT_MODAL_INFO_TEXT: 'By selecting accept, everyone will be able to hear and see you.',
        COPILOT_MODAL_PERMISSIONS_TEXT: 'After becoming a copilot, everyone will be able to hear and see you. Please give us access to your ' +
            'microphone and camera.',
        ACCEPT: 'Accept',
        DECLINE: 'Decline',
        FIRST_NAME_MODAL_HEADER_TEXT: 'Enter your first name',
        FIRST_NAME_MODAL_INFO_TEXT: 'Please enter your first name to participate in our live reading sessions.',
        STARTING_SOON: 'Starting soon!',
        LIVE: 'live',
        INFO: 'info',
        JOIN_NOW: 'JOIN Session',
        STARTING_IN: 'Starting in:',
        JOIN_BY_CLICKING_WEB: 'The session will begin automatically from this page at the designated start time.',
        WATCH: 'Storyvoice Preview',
        WATCH_VIDEO_TO_LEARN_MORE: 'First time on Storyvoice? Watch this quick video to learn more about the Storyvoice experience.',
        WHAT_TO_EXPECT: 'What to expect',
        FIRST_TIME_ON_STORYVOICE: 'First time on Storyvoice?',
        RUN_PRETEST: 'Please run a pre-test to make sure you are able to connect to the Storyvoice session by following',
        THIS_LINK: 'this link',
        TEACHERS_PARENTS_CHECK: 'Teachers and parents, check out the',
        STORYVOICE_GUIDE: 'Storyvoice guide',
        FOR_MORE_INFO: 'for more information on how to use the app.',
        LEARN_MORE: 'Learn more',
        JOIN_US_SCHOLASTIC: 'Join us Feb 3rd for World Read Aloud Day with Scholastic Book Clubs!',
        YOU_ARE_ENROLLED: 'You are successfully enrolled in',
        LIVE_SESSIONS_WILL_APPEAR_AUTOMATICALLY: 'Sessions started by your host will automatically begin on this page. You are also free to join Storyvoice public sessions listed below.',
        JOIN_READ_ALOUDS: 'Click on a card to learn more and join a live event at the scheduled time.',
        PRIVATE_STREAM_JOIN_READ_ALOUDS: 'In addition to reading with your teacher, you can also join any of the public Storyvoice sessions listed below.',
        THIS_WEEK_SCHEDULE: 'Upcoming reading schedule',
        SESSION_INFO: 'Session info',
        GOT_IT: 'Got it!',
        PINCH_TO_ZOOM: 'Pinch your screen to zoom!',
        PINCH_TO_ZOOM_DESCRIPTION: 'You can zoom in on the story by pinching the screen with your fingers.',
        FEEDBACK: 'Feedback',
        SIGN_OUT: 'Sign out',
        DOUBLE_CLICK_TO_ZOOM: 'Double click on the story to zoom!',
        DOUBLE_CLICK_TO_ZOOM_DESCRIPTION: 'You can zoom in by double clicking the story and moving around pages with your mouse.',
        SESSION_TIMES_IN_TIMEZONE: 'Session times listed are in {0}.',
        HELPFUL_TIPS_FOR_TEACHERS: 'Helpful tips for teachers',
        PLANNING_TO_USE_SCHOOL_ISSUED: 'Are you planning to use school Wi-Fi or a school-issued device to join Storyvoice sessions? If so, please {0} to ensure a smooth read-aloud experience.',
        RUN_OUR_TECH_CHECK: 'run our technology check',
        CHECKOUT_GUIDE: 'Check out the {0} for additional information on how to use the app.',
        HOST_LOGIN: 'Host Login',
        HOST_LOGIN_DESCRIPTION: 'Please enter your stream email and password',
        LOGIN: 'Login',
        AUDIENCE_QA: 'Audience Q&A',
        RESET: 'Reset',
        COLLECT_RESPONSES: 'Collect Responses',
        GATHER_AUDIENCE_QA: 'Gather audience questions and responses',
        TYPE_QUESTION_RESPONSE: 'Type in your question or response...',
        SEND: 'Send',
        RESPONSE_FROM_AUDIENCE: 'Audience Response',
        SHARE_RESPONSE: 'Share Response',
        COLLECTING_RESPONSES: 'Collecting responses...',
        SUCCESSFUL_TECH_CHECK_MESSAGE: 'Success, everything works!',
        FAILED_TECH_CHECK_MESSAGE: 'Unsuccesful, some of the app’s features are being blocked on your device or network.',
        REMIND_ME: 'Remind me',
    },
    ar: {
        WELCOME_STORYVOICE: 'أهلًا وسهلًا بك في Storyvoice!',
        RECEIVE_NOTIFICATION: 'سنُرسل إليك إشعارًا فَور بدء جلسة القراءة.',
        ABOUT: 'حَول',
        SETTINGS: 'الإعدادات',
        READER_LOGIN: 'تسجيل دخول القارئ',
        READER: 'القارئ',
        YOU_ARE_LIVE: 'أنت في البث المُباشِر!',
        EXIT_SESSION: 'مُغادرة الجلسة',
        CANCEL: 'إلغاء',
        RAISE_HAND_INFO_TEXT: 'لقد اخترت أن ترفع يدك للمُشارَكة! إذا ضغطت على "ارفع يدك"، يُصبح بإمكان القارئ أن يدعوك لتتمرّن على القراءة معه في البث المُباشر. وسيستمع إليك الجميع، بمن فيهم القارئ، فَور انضمامك إلى البث المُباشر.',
        RAISE_HAND_PERMISSIONS_TEXT: 'بعد أن ترفع يدك، يُصبح بإمكان مُعلّمك أن يدعوك للتّكلُّم. يُرجى منك السماح لنا بالوصول إلى المايكروفون لتتمكّن من التّحدُّث مع مُعلّمك على Storyvoice.',
        RAISE_HAND_PERMISSIONS_ANDROID_TEXT: 'يُرجى منك السماح لنا بالوصول إلى المايكروفون والكاميرا لتتمكّن من المُشاركة في الجلسة. وسنستمع إلى صوتك فقط.',
        ALLOW_ACCESS_MICROPHONE: 'إسمح لنا بالوصول إلى المايكروفون',
        ALLOW_ACCESS_MICROPHONE_CAMERA: 'إسمح لنا بالوصول إلى المايكروفون والكاميرا',
        RAISE_HAND: 'ارفع يدك',
        WHAT_IS_YOUR_NAME: 'أهلًا وسهلًا بك في Storyvoice!',
        ENTER_NAME_HERE: 'أدخِل إسمك',
        LETS_GO: 'هيّا بنا نقرأ مع Storyvoice!',
        BELOW_ACTIVE_SESSIONS: 'إليك في الأسفل الجلسات المُباشرة التي يتم بثها الآن.',
        PRESS_TO_JOIN: 'إضغط على الجلسة التي ترغب في الانضمام إليها.',
        WHERE_STORYTELLERS: 'Storyvoice، حيثُ يقرأ الرواة من كل أنحاء العالم القصص في بث مُباشر كل ليلة!',
        READING_SCHEDULE: 'برنامج القراءة المُتاحة للجميع:',
        CLASSROOM_LOGIN: 'تسجيل الدخول',
        GET_STARTED: 'لكي تبدأ في رحلة القراءة مع Storyvoice، الرّجاء إدخال إسمك أدناه.',
        FIRST_NAME: 'الإسم',
        CHECK_BACK: 'قُم بزيارة Storyvoice في وقتٍ لاحق لتتأكّد من تاريخ وتوقيت الجلسات المُقبلة.',
        TODAY: 'اليوم',
        TOMORROW: 'غدًا',
        AT: 'السّاعة:',
        HAVE_CLASSROOM_CODE: 'لديك رمز للانضمام إلى بث الجلسة؟ أدخله هنا.',
        AVAILABLE_READING_STREAMS_CLASSROOM: 'ستظهر هُنا جلسات القراءة المُتاحة عبر البث المُباشر فور بدء مُعلّمك بجلسته.',
        MAY_STORIES: 'لتُرافقك القصص بسلامة!',
        SUCCESSFULLY_JOINED_CLASSROOM: 'لقد انضممت إلى البث المُباشر لصفّك.',
        CHECK_INTERNET_CONNECTION: 'تحقّق من اتصالك بالإنترنت وحاول من جديد.',
        PERMISSION_UNAVAILABLE_ERROR: 'لم نتمكّن من طلب الأذون اللازمة. إذا كُنت تعتقد أنّك تواجه مشكلة تقنيّة، الرّجاء التّواصل معنا عبر البريد الإلكتروني:  support@storyvoice.live',
        PERMISSION_GENERAL_ERROR: 'حدث خطأ أثناء محاولة طلب الأذونات اللازمة. يرجى المحاولة مرة أخرى.',
        CHECK_CONNECTION_TRY_AGAIN: 'تحقّق من اتصالك بالإنترنت واضغط على المفتاح أدناه.',
        RECONNECT: 'إعادة الاتّصال',
        WE_READ: 'نقرأ قصص الأطفال باللغة الإنكليزية كل إثنين وأربعاء وجمعة. سيصلك إشعارًا عندما تبدأ جلسة البث المباشر التالية. إلى اللقاء قريبًا!',
        NEXT_SESSION_STARTS: 'ستبدأ جلسة القراءة التّالية:',
        SESSION_TODAY_BEGINS_SHORTLY: 'ستبدأ جلسة اليوم بعد قليل...',
        STORYTELLING_EXPERIENCES: 'اقرأ القصص وتمرّن على مهاراتك اللغوية مع رواة القصص من حول العالم.',
        START_READING: 'إبدأ القراءة!',
        READ_WITH_WORLD: 'رواية القصص للأطفال في كل مكان عبر البث المباشر!',
        LOADING: 'جاري التّحميل...',
        SESSION_STARTING_SOON: 'ستبدأ الجلسة قريبًا!',
        UPDATE_REQUIRED_TITLE: 'يجب تحديث التّطبيق',
        UPDATE_REQUIRED_TEXT: 'الرّجاء تحديث Storyvoice للاستمرار في استخدام هذا التّطبيق.',
        UPDATE_REQUIRED_BUTTON: 'تحديث',
        PRIVACY_POLICY: 'سياسة الخصوصيّة',
        TERMS: 'شروط الخدمة',
        BY_CONTINUING: 'من خلال المُتابعة، تُوافق على شروط Storyvoice',
        AND: 'و',
        PERMISSIONS_REQUIRED_TO_START_SESSION: 'للمُشاركة في البث المُباشر، الرّجاء السّماح لـStoryvoice بالوصول إلى المايكروفون والكاميرا في جهازك',
        PLEASE_ROTATE: 'الرّّجاء استدارة شاشة جهازك',
        THIS_ENABLED_BEST_VIEWING_EXPERIENCE: 'من أجل أفضل تجربة مُتابعة للقصة على Storyvoice.',
        COPILOT_MODAL_HEADER_TEXT: 'أهلا! لقد تم اختيارك كمساعد للقارئ.',
        COPILOT_MODAL_INFO_TEXT: 'باختيارك أوافق سيستطيع الجميع سماعك ورؤيتك.',
        COPILOT_MODAL_PERMISSIONS_TEXT: 'بعد أن تصبح مساعدًا للقارئ، سيتمكن الجميع من سماعك ورؤيتك. يجب ان تعطينا الإذن لإستعمال الميكرفون والكاميرا. ',
        ACCEPT: 'أوافق',
        DECLINE: 'أرفض',
        FIRST_NAME_MODAL_HEADER_TEXT: 'أدخِل إسمك',
        FIRST_NAME_MODAL_INFO_TEXT: 'لكي تبدأ في رحلة القراءة مع Storyvoice، الرّجاء إدخال إسمك أدناه.',
        STARTING_SOON: 'ستبدأ قريبًا',
        LIVE: 'مُباشِر',
        INFO: 'معلومات',
        JOIN_NOW: 'انضم الآن',
        STARTING_IN: 'ستبدأ بعد',
        JOIN_BY_CLICKING_WEB: 'يمكنك الانضمام إلى الجلسة مباشرة من خلال النقر على البطاقة في وقت البدء المحدد.',
        WATCH: 'شاهد',
        WATCH_VIDEO_TO_LEARN_MORE: 'شاهد هذا الفيديو القصير لمعرفة المزيد عن تجربة Storyvoice.',
        WHAT_TO_EXPECT: 'تعرف على المزيد حول تجربة Storyvoice',
        FIRST_TIME_ON_STORYVOICE: 'هل هذه هي المرة الأولى لك في Storyvoice؟',
        RUN_PRETEST: 'يرجى إجراء اختبار مسبق للتأكد من قدرتك على الاتصال بجلسة Storyvoice باتباعك',
        THIS_LINK: 'هذا الرابط',
        TEACHERS_PARENTS_CHECK: 'المعلمين وأولياء الأمور ، تحقق من',
        STORYVOICE_GUIDE: 'دليل Storyvoice',
        FOR_MORE_INFO: 'لمزيد من المعلومات حول كيفية استخدام التطبيق.',
        JOIN_US_SCHOLASTIC: 'انضم إلينا في الثالث من فبراير في اليوم العالمي للقراءة مع Scholastic Book Clubs!',
        LEARN_MORE: 'معرفة المزيد',
        YOU_ARE_ENROLLED: 'لقد تم تسجيلك بنجاح في',
        LIVE_SESSIONS_WILL_APPEAR_AUTOMATICALLY: 'ستبدأ الجلسات المباشرة مع معلمك تلقائيًا في هذه الصفحة.',
        JOIN_READ_ALOUDS: 'انضم إلى الجلسات المباشرة مع رواة القصص والمؤلفين من جميع أنحاء العالم.',
        PRIVATE_STREAM_JOIN_READ_ALOUDS: 'بالإضافة إلى القراءة مع معلمك ، يمكنك أيضًا الانضمام إلى أي من جلسات Storyvoice المجانية المدرجة أدناه.',
        THIS_WEEK_SCHEDULE: 'جدول القراءة',
        SESSION_INFO: 'معلومات حول الجلسة',
        GOT_IT: 'فهمت!',
        PINCH_TO_ZOOM: 'أبعد إصبعيك عن بعضهما البعض لتكبير القصة',
        PINCH_TO_ZOOM_DESCRIPTION: 'يمكنك الأن تكبير القصة بإبعاد إصبعيك عن بعضهما البعض.',
        DOUBLE_CLICK_TO_ZOOM: 'انقر مرتين فوق القصة للتكبير!',
        DOUBLE_CLICK_TO_ZOOM_DESCRIPTION: 'يمكنك التكبير عن طريق النقر مرتين على القصة.',
        FEEDBACK: 'قدم ملاحظاتك',
        SIGN_OUT: 'تسجيل الخروج',
        SESSION_TIMES_IN_TIMEZONE: 'أوقات الجلسات المُدرجة في {0}.',
        HELPFUL_TIPS_FOR_TEACHERS: 'نصائح مفيدة للمعلمين',
        PLANNING_TO_USE_SCHOOL_ISSUED: 'هل تخطط لاستخدام شبكة Wi-Fi للمدرسة أو جهاز أصدرته المدرسة للانضمام إلى جلسات Storyvoice؟ إذا كان الأمر كذلك ، فيرجى {0} لضمان تجربة سلسة.',
        RUN_OUR_TECH_CHECK: 'إجراء فحص التكنولوجيا لدينا',
        CHECKOUT_GUIDE: 'تحقق من {0} للحصول على معلومات إضافية حول كيفية استخدام التطبيق.',
        HOST_LOGIN: 'دخول القارئ',
        HOST_LOGIN_DESCRIPTION: 'يرجى إدخال البريد الإلكتروني وكلمة المرور الخاصة بك',
        LOGIN: 'تسجيل الدخول',
        AUDIENCE_QA: 'سؤال وجواب الجمهور',
        RESET: 'امسح القائمة',
        COLLECT_RESPONSES: 'اجمع الردود',
        GATHER_AUDIENCE_QA: 'اجمع أسئلة وإجابات المشاهدين',
        TYPE_QUESTION_RESPONSE: 'اكتب سؤالك أو إجابتك ...',
        SEND: 'أرسل',
        RESPONSE_FROM_AUDIENCE: 'رد من مشاهد',
        SHARE_RESPONSE: 'مشاركة',
        COLLECTING_RESPONSES: 'جاري تجميع الردود من المشاهدين...',
        SUCCESSFUL_TECH_CHECK_MESSAGE: 'النجاح ، كل شيء يعمل!',
        FAILED_TECH_CHECK_MESSAGE: 'غير ناجح ، تم حظر بعض ميزات التطبيق على جهازك أو شبكتك.',
        REMIND_ME: 'نبهني',
    },
};
exports.default = strings;
