"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
exports.Events = {
    // Audience Events
    JOIN_READING_SESSION: 'join_reading_session',
    LEAVE_READING_SESSION: 'leave_reading_session',
    RAISE_HAND_IN_READING_SESSION: 'raise_hand_in_reading_session',
    LOWER_HAND_IN_READING_SESSION: 'lower_hand_in_reading_session',
    PARTICIPATE_IN_READING_SESSION: 'participate_in_reading_session',
    LIKE_READING_SESSION: 'like_reading_session',
    FILL_FIRST_NAME: 'fill_first_name',
    JOIN_STREAM: 'join_stream',
    SEE_CELEBRATION: 'see_celebration',
    OPEN_RAISE_HAND_MODAL: 'open_raise_hand_modal',
    VIEW_AUDIENCE_HOME: 'view_audience_home',
    VIEW_AVAILABLE_SESSION: 'view_available_session',
    // New audience homescreen events
    PRESS_BANNER_LINK: 'press_banner_button',
    VIEW_PUBLIC_FEED: 'view_public_feed',
    VIEW_UPCOMING_SESSION_INFO: 'view_upcoming_session_info',
    VIEW_STARTING_SOON_SESSION_INFO: 'view_starting_soon_session_info',
    VIEW_TODAY_SESSION_INFO: 'view_today_session_info',
    PRESS_JOIN_SESSION_CARD: 'press_join_session_card',
    JOIN_SESSION_THROUGH_INFO: 'join_session_through_info',
    PRESS_PARTNER_CARD: 'press_partner_card',
    PRESS_REMIND_ME: 'press_remind_me',
    PRESS_SESSION_INFO: 'press_session_info',
    // Reader Events
    VIEW_STORY: 'view_story',
    CREATE_READING_SESSION: 'create_reading_session',
    END_READING_SESSION: 'end_reading_session',
    ADD_READER_TO_READING_SESSION: 'add_reader_to_reading_session',
    TOGGLE_SCROLL_LOCK: 'toggle_scroll_lock',
    TOGGLE_VIDEO: 'toggle_video',
    VIEW_AUDIENCE_LIST: 'view_audience_list',
    TRIGGER_CELEBRATION: 'trigger_celebration',
    TRIGGER_RANDOM_SELECTION: 'trigger_random_selection',
    SIGN_IN_AS_READER: 'sign_in_as_reader',
    // COMMON
    SIGN_OUT: 'sign_out',
    PRESS_GETTING_STARTED: 'press_getting_started',
    ACTIVATE_AUDIO: 'activate_audio',
    VIEW_SETTINGS: 'view_settings',
    // TECHNOLOGY CHECK
    VIEW_TECHNOLOGY_CHECK: 'view_technology_check',
    START_TECHNOLOGY_CHECK: 'start_technology_check',
    VIEW_TECHNOLOGY_CHECK_REPORT: 'view_technology_check_report',
};
