"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHeartsCount = void 0;
const react_1 = require("react");
const context_1 = require("../../context");
const models_1 = require("../../models");
const utils_1 = require("../utils");
/**
 * Retrieves the session likes count every 1 second. Must be child of SessionProvider.
 */
const useHeartsCount = () => {
    const [heartsCount, setHeartsCount] = react_1.useState(0);
    const { session } = context_1.useSessionContext();
    const calculateHearts = react_1.useCallback(async () => {
        if (session) {
            const totalLikes = await models_1.getSessionLikesCount(session.id);
            setHeartsCount(totalLikes);
        }
    }, [session]);
    utils_1.useInterval(calculateHearts, 1000);
    return heartsCount;
};
exports.useHeartsCount = useHeartsCount;
