"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSessionContext = exports.SessionProvider = void 0;
const react_1 = __importStar(require("react"));
const types_1 = require("../types");
const models_1 = require("../models");
const SessionContext = react_1.default.createContext({
    user: null,
    session: null,
    sessionActions: null,
    sessionListeners: null,
    sessionHostActions: null,
    isHost: false,
});
const SessionProvider = ({ session, user, children, isCopilot = false, isHost = false, }) => {
    const [didJoinSession, setDidJoinSession] = react_1.useState(false);
    const sessionId = session.id;
    react_1.useEffect(() => {
        if (!user || !session) {
            return;
        }
        const join = async () => {
            if (isHost) {
                await models_1.hostJoinSession(sessionId, user);
            }
            else {
                await models_1.joinSession(sessionId, user);
                isCopilot && (await models_1.joinSessionAsCopilot(sessionId, user));
            }
            setDidJoinSession(true);
        };
        join();
        return () => {
            models_1.leaveSession(sessionId, user);
        };
    }, [session, user, sessionId, isCopilot, isHost]);
    const actions = react_1.useMemo(() => {
        if (!didJoinSession) {
            return null;
        }
        return {
            join: () => models_1.joinSession(sessionId, user),
            leave: () => models_1.leaveSession(sessionId, user),
            like: () => models_1.likeSession(sessionId),
            raiseHand: () => models_1.raiseHandInSession(sessionId, user),
            lowerHand: () => models_1.lowerHandInSession(sessionId, user),
            removeTalkingUser: () => models_1.removeTalkingUser(sessionId),
            acceptCopilotInvitation: () => models_1.setActiveCopilot(sessionId, {
                ...user,
                invitationStatus: types_1.CopilotInvitationStatus.Accepted,
            }),
            dismissCopilotInvitation: () => models_1.setActiveCopilot(sessionId, {
                ...user,
                invitationStatus: types_1.CopilotInvitationStatus.Dismissed,
            }),
            addResponse: (response) => models_1.addResponse(sessionId, { ...user, response }),
        };
    }, [sessionId, user, didJoinSession]);
    const hostActions = react_1.useMemo(() => {
        if (!didJoinSession || !isHost) {
            return null;
        }
        return {
            triggerCelebration: async () => {
                await models_1.triggerCelebration(sessionId, 'fireworks');
                setTimeout(() => {
                    models_1.removeCelebration(sessionId);
                }, 3000);
            },
            setCurrentPage: async (page) => models_1.setCurrentPage(sessionId, page),
            setVideoState: async (expanded) => expanded ? models_1.enableReadingMode(sessionId) : models_1.disableReadingMode(sessionId),
            setScrollingLock: async (enabled) => enabled ? models_1.enableScrolling(sessionId) : models_1.disableScrolling(sessionId),
            setActiveParticipant: async (participant) => models_1.allowUserToTalk(sessionId, participant),
            getRequests: async () => models_1.getSessionRequests(sessionId, 50),
            clearRequests: async () => models_1.resetHandsInSession(sessionId),
            getAudience: async () => models_1.getSessionAudience(sessionId, 50),
            getCopilots: async () => models_1.getSessionCopilots(sessionId, 20),
            getResponses: async () => models_1.getResponses(sessionId, 20),
            resetQuestions: async () => models_1.resetQuestions(sessionId),
            setHighlightedResponse: async (response) => models_1.setHighlightedResponse(sessionId, response),
            clearHighlightedResponse: async () => models_1.clearHighlightedResponse(sessionId),
            setPrompt: async (prompt) => models_1.setPrompt(sessionId, prompt),
            clearPrompt: async () => models_1.clearPrompt(sessionId),
            setFocus: async (focus) => models_1.setFocus(sessionId, focus),
        };
    }, [sessionId, didJoinSession, isHost]);
    const listeners = react_1.useMemo(() => {
        if (!didJoinSession) {
            return null;
        }
        return {
            celebration: (onCelebration) => models_1.subscribeToSessionCelebrations(sessionId, onCelebration),
            randomSelection: (onRandomSelection) => models_1.subscribeToSessionRandomPopup(sessionId, onRandomSelection),
            sessionReader: (onSessioReaderUpdated) => models_1.subscribeToSessionReader(sessionId, onSessioReaderUpdated),
            hostPage: (onPageChange) => models_1.subscribeToSessionPage(sessionId, onPageChange),
            hostLock: (onHostLockChange) => models_1.subscribeToSessionScrolling(sessionId, onHostLockChange),
            hostOrientation: (onHostOrientationChange) => models_1.subscribeToReaderOrientation(sessionId, onHostOrientationChange),
            readingMode: (onReadingModeChange) => models_1.subscribeToSessionReadingMode(sessionId, onReadingModeChange),
            activeCopilot: (onActiveCopilotChange) => models_1.subscribeToActiveCopilot(sessionId, onActiveCopilotChange),
            highlightedResponse: (onHighlightedResponseChange) => models_1.subscribeToHighlightedResponse(sessionId, onHighlightedResponseChange),
            prompt: (onPromptChange) => models_1.subscribeToPrompt(sessionId, onPromptChange),
            focus: (onSessionFocusChange) => models_1.subscribeToSessionFocus(sessionId, onSessionFocusChange),
        };
    }, [sessionId, didJoinSession]);
    return (react_1.default.createElement(SessionContext.Provider, { value: {
            user,
            session,
            sessionActions: actions,
            sessionListeners: listeners,
            sessionHostActions: hostActions,
            isHost,
        } }, children));
};
exports.SessionProvider = SessionProvider;
const useSessionContext = () => {
    const context = react_1.useContext(SessionContext);
    if (!context) {
        throw new Error('useSessionContext must be used within a SessionsProvider');
    }
    return context;
};
exports.useSessionContext = useSessionContext;
