"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sampleImageUri = exports.sampleText = exports.columnStyles = void 0;
const react_native_1 = require("react-native");
exports.columnStyles = react_native_1.StyleSheet.create({
    column: {
        flex: 1,
        height: '100%',
        width: '50%',
    },
});
exports.sampleText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris pulvinar consectetur dolor eget sollicitudin. Duis vel aliquet risus. Donec cursus a massa aliquet malesuada. Duis sit amet tortor purus. Vivamus eu arcu justo. Curabitur sollicitudin tellus urna, iaculis semper orci tristique et. Mauris euismod augue quis nisi tempor, in tristique quam lacinia. Nam nec pellentesque orci. Fusce nec quam cursus, iaculis ex quis, ultrices nibh. Pellentesque eros nisi, tempor vitae felis eget, suscipit posuere nibh. Nulla velit elit, scelerisque nec aliquam ut, viverra sit amet lectus. Fusce sodales turpis ac lacus maximus, eu lobortis lacus rutrum.';
exports.sampleImageUri = 'https://1.bp.blogspot.com/-ZlQd2YkNaF8/WQPxTvX8K2I/AAAAAAAAAK0/nkHaEcpXPF4f24y6U6TG8I19tdmTKwZzQCLcB/s400/laila1.jpg';
