"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAudienceBanner = void 0;
const react_1 = require("react");
const remoteConfig_1 = require("./remoteConfig");
const defaults_1 = require("./defaults");
function useAudienceBanner() {
    const [title, setTitle] = react_1.useState(defaults_1.audienceBannerDefault.title);
    const [subtitle, setSubtitle] = react_1.useState();
    const [button, setButton] = react_1.useState();
    const [coverUrl, setCoverUrl] = react_1.useState();
    react_1.useEffect(() => {
        try {
            const audienceBanner = JSON.parse(remoteConfig_1.remoteConfig().getValue('audience_banner').asString());
            setTitle(audienceBanner.title);
            setSubtitle(audienceBanner.subtitle);
            setButton(audienceBanner.button);
            setCoverUrl(audienceBanner.coverUrl);
        }
        catch {
            // TODO: track exception
        }
    }, []);
    return {
        title,
        subtitle,
        button,
        coverUrl,
    };
}
exports.useAudienceBanner = useAudienceBanner;
