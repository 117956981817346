"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionStatus = void 0;
/**
 * @category Session
 */
var SessionStatus;
(function (SessionStatus) {
    SessionStatus["Completed"] = "completed";
    SessionStatus["Live"] = "live";
    SessionStatus["Scheduled"] = "scheduled";
})(SessionStatus = exports.SessionStatus || (exports.SessionStatus = {}));
