"use strict";
/* eslint-disable react-native/no-raw-text */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginScreen = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const components_1 = require("@ui-kitten/components");
const react_hook_form_1 = require("react-hook-form");
const forms_1 = require("../../../hooks/forms");
const context_1 = require("../../../context");
function LoginScreen({ onBack, onSubmit, }) {
    const { translations } = context_1.useLocalizationContext();
    const [errorMessage, setErrorMessage] = react_1.useState('');
    const { control, handleSubmit, formState } = forms_1.useReaderLoginForm();
    const { isValid, isSubmitting, errors } = formState;
    const BackIcon = (style) => (react_1.default.createElement(components_1.Icon, { ...style, name: "arrow-back", fill: "black" }));
    const BackAction = onBack
        ? () => react_1.default.createElement(components_1.TopNavigationAction, { icon: BackIcon, onPress: onBack })
        : undefined;
    const onLogin = handleSubmit(async (data) => {
        try {
            onSubmit && (await onSubmit(data.email, data.password));
            setErrorMessage('');
        }
        catch (e) {
            switch (e.code) {
                case 'auth/wrong-password':
                case 'auth/user-not-found':
                    setErrorMessage('Incorrect email and/or password');
                    break;
                default:
                    setErrorMessage(e.message);
            }
        }
    });
    const LoadingIndicator = isSubmitting
        ? () => react_1.default.createElement(components_1.Spinner, { size: "small" })
        : undefined;
    return (react_1.default.createElement(react_native_1.SafeAreaView, { testID: "login_reader_screen" },
        react_1.default.createElement(react_native_1.StatusBar, { barStyle: "dark-content" }),
        react_1.default.createElement(components_1.TopNavigation, { accessoryLeft: BackAction }),
        react_1.default.createElement(react_native_1.ScrollView, { contentContainerStyle: styles.contentContainer, centerContent: true },
            react_1.default.createElement(react_native_1.KeyboardAvoidingView, { style: styles.container, behavior: "padding" },
                react_1.default.createElement(components_1.Text, { category: "h3", style: styles.headerText }, translations.HOST_LOGIN),
                react_1.default.createElement(components_1.Text, { style: styles.descriptionText }, translations.HOST_LOGIN_DESCRIPTION),
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, render: ({ onChange, onBlur, value }) => {
                        var _a;
                        return (react_1.default.createElement(components_1.Input, { testID: "login_username_input", autoCompleteType: "email", keyboardType: "email-address", autoCapitalize: "none", textContentType: "emailAddress", placeholder: "joe.doe@gmail.com", label: "Email", value: value, style: styles.input, size: "large", onBlur: onBlur, onChangeText: onChange, status: errors.email ? 'danger' : 'primary', caption: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message }));
                    }, name: "email", defaultValue: "" }),
                react_1.default.createElement(react_hook_form_1.Controller, { control: control, render: ({ onChange, onBlur, value }) => {
                        var _a;
                        return (react_1.default.createElement(components_1.Input, { testID: "login_password_input", label: "Password", secureTextEntry: true, autoCapitalize: "none", textContentType: "password", style: [styles.input, styles.spacing], size: "large", value: value, onBlur: onBlur, onChangeText: onChange, status: errors.password ? 'danger' : 'primary', caption: (_a = errors.password) === null || _a === void 0 ? void 0 : _a.message }));
                    }, name: "password", defaultValue: "" }),
                react_1.default.createElement(components_1.Text, { style: styles.spacing, status: "danger", testID: "login_error_message" }, errorMessage),
                react_1.default.createElement(components_1.Button, { testID: "login_reader_button", style: styles.button, size: "large", disabled: isSubmitting || !isValid, accessoryLeft: LoadingIndicator, onPress: onLogin }, translations.LOGIN)))));
}
exports.LoginScreen = LoginScreen;
const styles = react_native_1.StyleSheet.create({
    button: {
        marginTop: 30,
        maxWidth: 400,
        width: '100%',
    },
    container: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: 30,
    },
    contentContainer: {
        flexGrow: 1,
    },
    descriptionText: { marginBottom: 30, textAlign: 'center' },
    headerText: { marginBottom: 10 },
    input: {
        maxWidth: 400,
        width: '100%',
    },
    spacing: { marginTop: 40 },
});
