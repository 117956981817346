"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = void 0;
const react_1 = require("react");
/**
 * Triggers callback every interval specified in delay
 * @param callback
 * @param delay
 */
function useInterval(callback, delay) {
    const savedCallback = react_1.useRef();
    // Remember the latest callback.
    react_1.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    react_1.useEffect(() => {
        function tick() {
            savedCallback.current !== undefined && savedCallback.current();
        }
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}
exports.useInterval = useInterval;
