"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QAResponsesOverlay = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const components_1 = require("../../../../../components");
const context_1 = require("../../../../../context");
const hooks_1 = require("../../../../../hooks");
function QAResponsesOverlay({ visible, }) {
    const [responses, setResponses] = react_1.useState([]);
    const [isOverlayVisible, setIsOverlayVisible] = react_1.useState(visible);
    const [highlightedResp, setHighlightedResp] = react_1.useState();
    const [activePrompt, setActivePrompt] = react_1.useState(null);
    const { sessionHostActions, sessionListeners } = context_1.useSessionContext();
    react_1.useEffect(() => {
        setIsOverlayVisible(visible);
    }, [visible]);
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.prompt((prompt) => setActivePrompt(prompt));
    }, [sessionListeners]);
    const checkForResponses = async () => { var _a; return setResponses((_a = (await (sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.getResponses()))) !== null && _a !== void 0 ? _a : []); };
    hooks_1.useInterval(checkForResponses, 3000);
    const handleCollectResponses = (text) => {
        sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.setPrompt({ text: text !== null && text !== void 0 ? text : '' });
    };
    const handlePressItem = (item) => {
        setHighlightedResp(item);
    };
    const handleClearList = () => {
        sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.resetQuestions();
        setResponses([]);
    };
    const handleDismissResponse = () => {
        setHighlightedResp(undefined);
        setIsOverlayVisible(true);
    };
    const handleShareResponse = () => {
        highlightedResp &&
            (sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.setHighlightedResponse(highlightedResp));
        setIsOverlayVisible(false);
        setHighlightedResp(undefined);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.View, { style: [styles.container, isOverlayVisible && styles.showContainer] },
            react_1.default.createElement(components_1.HostQAOverlay, { isVisible: isOverlayVisible, onCollectResponses: handleCollectResponses, items: responses, onPressItem: handlePressItem, onResetQuestions: handleClearList, activePrompt: activePrompt }),
            react_1.default.createElement(components_1.ResponseQAModal, { isVisible: Boolean(highlightedResp), contentText: highlightedResp === null || highlightedResp === void 0 ? void 0 : highlightedResp.response, onDismiss: handleDismissResponse, onShare: handleShareResponse }))));
}
exports.QAResponsesOverlay = QAResponsesOverlay;
const styles = react_native_1.StyleSheet.create({
    container: {
        bottom: 80,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 41,
        zIndex: -1,
    },
    showContainer: {
        zIndex: 10,
    },
});
