"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QAContainer = void 0;
const react_1 = __importStar(require("react"));
const components_1 = require("../../../components");
const context_1 = require("../../../context");
function QAContainer() {
    const [prompt, setPrompt] = react_1.useState(null);
    const [isActiveCopilotMe, setIsActiveCopilotMe] = react_1.useState(false);
    const [isActiveParticipantMe, setIsActiveParticipantMe] = react_1.useState(false);
    const [highlightedResponse, setHighlightedResponse,] = react_1.useState(null);
    const { isHost, sessionListeners, sessionActions, sessionHostActions, user, } = context_1.useSessionContext();
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.activeCopilot((activeCopilot) => setIsActiveCopilotMe((activeCopilot === null || activeCopilot === void 0 ? void 0 : activeCopilot.id) === (user === null || user === void 0 ? void 0 : user.id)));
    }, [sessionListeners, user]);
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.sessionReader((participant) => setIsActiveParticipantMe((participant === null || participant === void 0 ? void 0 : participant.id) === (user === null || user === void 0 ? void 0 : user.id)));
    }, [sessionListeners, user]);
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.highlightedResponse((response) => setHighlightedResponse(response));
    }, [sessionListeners]);
    react_1.useEffect(() => {
        return sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.prompt((p) => setPrompt(p));
    }, [sessionListeners]);
    const handleSubmitResponse = (text) => {
        sessionActions === null || sessionActions === void 0 ? void 0 : sessionActions.addResponse(text);
        setPrompt(null);
    };
    const handleDismiss = () => setPrompt(null);
    const handleDismissResponseModal = () => {
        setHighlightedResponse(null);
        isHost && (sessionHostActions === null || sessionHostActions === void 0 ? void 0 : sessionHostActions.clearHighlightedResponse());
    };
    const isInputPromptVisible = prompt !== null && !isHost && !isActiveCopilotMe && !isActiveParticipantMe;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.ResponseQAModal, { isVisible: highlightedResponse !== null, contentText: highlightedResponse === null || highlightedResponse === void 0 ? void 0 : highlightedResponse.response, onDismiss: handleDismissResponseModal }),
        react_1.default.createElement(components_1.InputQAModal, { text: prompt === null || prompt === void 0 ? void 0 : prompt.text, isVisible: isInputPromptVisible, onDismiss: handleDismiss, onSubmit: handleSubmitResponse })));
}
exports.QAContainer = QAContainer;
