"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logEvent = void 0;
const config_1 = require("../../config");
const firebase_1 = require("../../firebase");
function logEvent(eventName, params) {
    if (config_1.Config.Firebase.enableEmulators) {
        return;
    }
    firebase_1.analytics().logEvent(eventName, params);
}
exports.logEvent = logEvent;
