"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToSessionFocus = exports.setFocus = void 0;
const firebase_1 = require("../../firebase");
const helpers_1 = require("./helpers");
const focusRef = (sessionId) => firebase_1.database().ref(`/sessions/${sessionId}/focus`);
const setFocus = async (sessionId, focus) => {
    await focusRef(sessionId).set(focus);
};
exports.setFocus = setFocus;
const subscribeToSessionFocus = (sessionId, onSessionFocusChange) => {
    const ref = focusRef(sessionId);
    const onChange = (value) => onSessionFocusChange(helpers_1.toVal(value));
    ref.on('value', onChange);
    return () => ref.off('value', onChange);
};
exports.subscribeToSessionFocus = subscribeToSessionFocus;
