"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackView = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const StackView = ({ onPress, children, isPortrait = false, isExpanded = false, }) => {
    const [expanded, setExpanded] = react_1.useState(true);
    react_1.useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);
    const onPressStackView = () => {
        setExpanded(!expanded);
        onPress && onPress();
    };
    return (react_1.default.createElement(react_native_1.View, { style: expanded && styles.expandedContainer },
        react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: onPressStackView, style: [
                styles.container,
                isPortrait || react_native_1.Platform.OS === 'web'
                    ? {
                        flexDirection: 'row',
                    }
                    : {
                        flexDirection: 'column',
                    },
            ] }, children)));
};
exports.StackView = StackView;
const styles = react_native_1.StyleSheet.create({
    container: {
        borderRadius: 10,
        height: '100%',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        width: '100%',
        zIndex: 1,
    },
    expandedContainer: {
        alignSelf: 'center',
        height: '50vh',
        justifyContent: 'center',
        marginTop: '-75vh',
        width: '90vw',
    },
});
