"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatingHearts = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const HeartShape_1 = require("./HeartShape");
const AnimatedShape_1 = require("./AnimatedShape");
const FloatingHearts = ({ style, count = -1, color, renderCustomShape, }) => {
    const [hearts, setHearts] = react_1.useState([]);
    const [height, setHeight] = react_1.useState();
    const prevCount = react_1.useRef(count);
    const apparentHearts = react_1.useRef(hearts);
    const getBlankHeart = () => {
        const heart = { id: 0, right: 0 };
        return heart;
    };
    const getRandomNumber = (min, max) => {
        return Math.random() * (max - min) + min;
    };
    const removeHeart = (id) => {
        apparentHearts.current = apparentHearts.current.filter((heart) => heart.id !== id);
    };
    const handleOnLayout = (e) => {
        setHeight(e.nativeEvent.layout.height);
    };
    react_1.useEffect(() => {
        const createHeart = (index) => {
            const heart = {
                id: index,
                right: getRandomNumber(50, 150),
            };
            return heart;
        };
        const oldCount = prevCount.current;
        const newCount = count;
        const numHearts = newCount - oldCount;
        prevCount.current = count;
        if (numHearts <= 0) {
            return;
        }
        const items = Array(numHearts).fill(getBlankHeart());
        const newHearts = items.map((_item, i) => oldCount + i).map(createHeart);
        apparentHearts.current = apparentHearts.current.concat(newHearts);
        setHearts(apparentHearts.current);
    }, [count]);
    const isReady = height !== null;
    const heartProps = {};
    if (color !== null) {
        heartProps.color = color;
    }
    return (react_1.default.createElement(react_native_1.View, { style: [styles.container, style], onLayout: handleOnLayout, pointerEvents: "none" }, isReady &&
        hearts.map((heart) => (react_1.default.createElement(AnimatedShape_1.AnimatedShape, { key: heart.id, height: height ? height : 0, style: { right: heart.right }, onComplete: () => {
                removeHeart(heart.id);
            } }, renderCustomShape ? (renderCustomShape(heart.id)) : (react_1.default.createElement(HeartShape_1.HeartShape, { ...heartProps })))))));
};
exports.FloatingHearts = FloatingHearts;
const styles = react_native_1.StyleSheet.create({
    container: {
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
    },
});
