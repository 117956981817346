"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAudienceCount = void 0;
const react_1 = require("react");
const context_1 = require("../../context");
const models_1 = require("../../models");
const utils_1 = require("../utils");
/**
 * Retrieves the audience count every 1 second. Must be child of SessionProvider.
 */
const useAudienceCount = () => {
    const [audienceCount, setAudienceCount] = react_1.useState(0);
    const { session } = context_1.useSessionContext();
    const checkAudienceCount = react_1.useCallback(async () => {
        if (session) {
            const total = await models_1.getSessionAudienceCount(session.id);
            setAudienceCount(total);
        }
    }, [session]);
    utils_1.useInterval(checkAudienceCount, 1000);
    return audienceCount;
};
exports.useAudienceCount = useAudienceCount;
