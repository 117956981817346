"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = void 0;
exports.Colors = {
    celebrationBackground: 'rgba(0, 0, 0, 0.7)',
    white: 'white',
    backdrop: 'rgba(0, 0, 0, 0.4)',
    gray: 'gray',
    black: 'black',
    transparent: 'transparent',
    // blue that is seen by viewer who are not the participant
    viewerColor: '#27A2F8',
    // bright green which is seen by the participating audience member when they're live
    participantColor: '#3EDDA8',
    // purple
    readerColor: '#8B63FF',
    // orange
    copilotColor: '#FF8B2D',
    // light red
    dangerColor: '#FF396F',
    linkColor: 'blue',
    // warm white
    privateStreamCardColor: '#FAF7F2',
    pageNumerIndicatorColor: '#AAA',
    imageColumnOverlayColor: 'rgba(0, 0, 0, 0.05)',
    imageLoadingOverlayColor: 'rgba(255, 255, 255, 1)',
    textColumnOverlayColor: 'rgba(0, 0, 0, 0.02)',
    liveRedColor: '#F86D6D',
    audienceCountBackgroundColor: 'rgba(57, 57, 57, 0.5)',
};
