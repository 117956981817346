"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkStreamCode = exports.getStreamByCode = void 0;
const firebase_1 = require("../firebase");
const streamsCollection = firebase_1.firestore().collection('streams');
const streamConverter = {
    toFirestore(stream) {
        return stream;
    },
    fromFirestore(snapshot) {
        const data = snapshot.data();
        return {
            id: snapshot.id,
            code: snapshot.id,
            excludedCategories: data.excludedCategories,
            publicEnabled: data.publicEnabled,
        };
    },
};
const getStreamByCode = async (code) => {
    return streamsCollection.doc(code).withConverter(streamConverter).get();
};
exports.getStreamByCode = getStreamByCode;
const checkStreamCode = async (code) => {
    return (await exports.getStreamByCode(code)).exists;
};
exports.checkStreamCode = checkStreamCode;
