"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FETCH_INTERVAL = exports.defaultConfig = exports.partnersCardsDefault = exports.audienceBannerDefault = void 0;
const config_1 = require("../../config");
exports.audienceBannerDefault = {
    title: 'Read with the world',
    subtitle: 'A global storytelling experience for children everywhere',
    button: {
        link: 'https://www.youtube.com/watch?v=d5Rg3h1X6-Q&feature=youtu.be',
        status: 'info',
        text: 'Learn more',
    },
    coverUrl: 'https://firebasestorage.googleapis.com/v0/b/storyvoice-e05f2.appspot.com/o/banner%2Fbanner.png?alt=media&token=41221f4d-10d0-496b-bcbc-413ca0a0b709',
};
exports.partnersCardsDefault = config_1.Config.Firebase
    .enableEmulators
    ? [
        {
            position: 10,
            promotionName: 'test_promotion',
            coverImageUrl: 'https://firebasestorage.googleapis.com/v0/b/storyvoice-e05f2.appspot.com/o/session-cards%2Fhomebase.png?alt=media&token=d0d3c6f7-c839-447c-bd17-36f48619f87e',
            button: {
                link: 'https://google.com',
                text: 'Learn More',
                size: 'small',
                status: 'control',
            },
            label: {
                text: 'Promotion',
                status: 'info',
            },
        },
    ]
    : [];
exports.defaultConfig = {
    audience_banner: JSON.stringify(exports.audienceBannerDefault),
    partners_cards: JSON.stringify(exports.partnersCardsDefault),
};
// 6 hours
exports.FETCH_INTERVAL = 21600000;
