"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeartsContainer = void 0;
const react_1 = __importStar(require("react"));
const components_1 = require("../components");
const hooks_1 = require("../hooks");
const HeartsContainer = ({ localHeartsCount = 0, }) => {
    const remoteHeartsCount = hooks_1.useHeartsCount();
    const [totalHeartsCount, setTotalHeartsCount] = react_1.useState(0);
    react_1.useEffect(() => {
        localHeartsCount > 0 && setTotalHeartsCount((t) => ++t);
    }, [localHeartsCount]);
    react_1.useEffect(() => {
        remoteHeartsCount > 0 && setTotalHeartsCount((t) => ++t);
    }, [remoteHeartsCount]);
    return react_1.default.createElement(components_1.FloatingHearts, { count: totalHeartsCount });
};
exports.HeartsContainer = HeartsContainer;
