"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HostQACollectResponses = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const context_1 = require("../../../../context");
const uiKitten_1 = require("../../../uiKitten");
function HostQACollectResponses({ onCollectResponses, }) {
    const { translations } = context_1.useLocalizationContext();
    const [input, setInput] = react_1.useState();
    const handleCollectResponses = () => onCollectResponses && onCollectResponses(input);
    return (react_1.default.createElement(react_native_1.View, { style: styles.container },
        react_1.default.createElement(uiKitten_1.Text, { style: styles.header }, translations.GATHER_AUDIENCE_QA),
        react_1.default.createElement(uiKitten_1.Input, { status: "primary", placeholder: 'Type in an prompt (optional)', value: input, style: styles.input, onChangeText: (nextValue) => setInput(nextValue) }),
        react_1.default.createElement(uiKitten_1.Button, { status: "success", onPress: handleCollectResponses }, translations.COLLECT_RESPONSES)));
}
exports.HostQACollectResponses = HostQACollectResponses;
const styles = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
    },
    header: { paddingTop: 20 },
    input: { maxWidth: '100%', paddingVertical: 20, width: 300 },
});
