"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudienceHome = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const Header_1 = require("../../Header");
const utils_1 = require("../../../utils");
const Feed_1 = require("../../Feed/Feed");
const ReadAloudBanner_1 = require("./ReadAloudBanner");
const hooks_1 = require("../../../hooks");
const WebInstructionsFooter_1 = require("./WebInstructionsFooter");
const FeedHeader_1 = require("./FeedHeader");
function AudienceHome({ sessions, streamCode = 'public', navigateToSettings, navigateToSessionInfo, navigateToLiveSession, setSessionReminder, }) {
    const promotions = hooks_1.usePartnersCards();
    react_1.useEffect(() => {
        utils_1.logEvent(utils_1.Events.VIEW_PUBLIC_FEED, { streamCode });
    }, [streamCode]);
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(Header_1.Header, { navigateToSettings: navigateToSettings }),
        react_1.default.createElement(react_native_1.ScrollView, { style: styles.container, centerContent: true },
            react_1.default.createElement(ReadAloudBanner_1.ReadAloudBanner, null),
            react_1.default.createElement(FeedHeader_1.FeedHeader, { streamCode: streamCode }),
            react_1.default.createElement(Feed_1.Feed, { navigateToLiveSession: navigateToLiveSession, navigateToSessionInfo: navigateToSessionInfo, setSessionReminder: setSessionReminder, sessions: sessions, promotions: promotions }),
            react_1.default.createElement(WebInstructionsFooter_1.WebInstructionsFooter, { streamCode: streamCode }))));
}
exports.AudienceHome = AudienceHome;
const styles = react_native_1.StyleSheet.create({
    container: {
        alignSelf: 'center',
        maxWidth: 1100,
        minWidth: 350,
        width: '100%',
    },
});
