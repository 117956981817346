"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopilotsOverlay = void 0;
const react_1 = __importStar(require("react"));
const components_1 = require("../../../../../components");
const hooks_1 = require("../../../../../hooks");
const types_1 = require("../../../../../types");
const context_1 = require("../../../../../context");
const models_1 = require("../../../../../models");
function CopilotsOverlay({ visible }) {
    const copilots = hooks_1.useCopilots();
    const [activeCopilot, setCopilot] = react_1.useState(null);
    const { session, sessionListeners } = context_1.useSessionContext();
    react_1.useEffect(() => {
        sessionListeners === null || sessionListeners === void 0 ? void 0 : sessionListeners.activeCopilot((copilot) => setCopilot(copilot));
    }, [sessionListeners]);
    const onSelectCopilot = async (user) => {
        if (session) {
            await models_1.setActiveCopilot(session.id, {
                ...user,
                invitationStatus: types_1.CopilotInvitationStatus.Invited,
            });
        }
    };
    const onCancelInvitation = () => session && models_1.setActiveCopilot(session === null || session === void 0 ? void 0 : session.id, null);
    return (react_1.default.createElement(components_1.Overlay, { left: 300, visible: visible, headerText: "Invite a co-pilot", descriptionText: "Co-pilots are able to display their video and audio with your audience.", users: copilots, onPressItem: onSelectCopilot, emptyText: "No co-pilots" }, (activeCopilot === null || activeCopilot === void 0 ? void 0 : activeCopilot.invitationStatus) === types_1.CopilotInvitationStatus.Invited && (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.Text, { category: "c1", style: { paddingHorizontal: 20, marginBottom: 10 } },
            "You have invited ",
            activeCopilot.fullName,
            " to join you but they did not accept your invitation yet."),
        react_1.default.createElement(components_1.Button, { status: "danger", size: "small", onPress: onCancelInvitation, style: { width: 200, alignSelf: 'center' } }, "Cancel Invite")))));
}
exports.CopilotsOverlay = CopilotsOverlay;
