"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Feed = void 0;
const react_1 = __importStar(require("react"));
const FeedCardList_1 = require("./FeedCardList");
function getFeedWithPromotions(sessions, promotions) {
    const feedCards = sessions.map((session) => {
        return {
            type: 'session',
            data: session,
        };
    });
    promotions.forEach((promotion) => {
        var _a;
        const card = {
            type: 'partner',
            data: promotion,
        };
        feedCards.splice((_a = promotion.position) !== null && _a !== void 0 ? _a : feedCards.length, 0, card);
    });
    return feedCards;
}
function Feed({ navigateToSessionInfo, navigateToLiveSession, setSessionReminder, sessions = [], promotions = [], }) {
    const feedData = react_1.useMemo(() => {
        return getFeedWithPromotions(sessions, promotions);
    }, [sessions, promotions]);
    return (react_1.default.createElement(FeedCardList_1.FeedCardList, { onPressInfo: navigateToSessionInfo, onPressJoin: navigateToLiveSession, onPressRemindMe: setSessionReminder, data: feedData }));
}
exports.Feed = Feed;
