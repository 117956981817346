"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimatedShape = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const constants_1 = require("../../../constants");
const AnimatedShape = ({ height, onComplete, style, children, }) => {
    const [position] = react_1.useState(new react_native_1.Animated.Value(0));
    const [animationsReady, setAnimationsReady] = react_1.useState(false);
    const rendered = react_1.useRef(false);
    const xAnimation = react_1.useRef(new react_native_1.Animated.Value(0));
    const scaleAnimation = react_1.useRef(new react_native_1.Animated.Value(0));
    const rotateAnimation = react_1.useRef(new react_native_1.Animated.Value(0));
    const opacityAnimation = react_1.useRef(new react_native_1.Animated.Value(0));
    const yAnimation = react_1.useRef(position.interpolate({
        inputRange: [Math.ceil(height) * -1, 0],
        outputRange: [Math.ceil(height), 0],
    }));
    react_1.useEffect(() => {
        const animation = react_native_1.Animated.timing(position, {
            duration: 2000,
            useNativeDriver: true,
            toValue: height * -1,
        });
        animation.start(onComplete);
        return () => {
            animation.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, position]);
    const getAnimationStyle = () => {
        if (!animationsReady) {
            return { opacity: 0 };
        }
        return {
            transform: [
                { translateY: position },
                { translateX: xAnimation.current },
                { scale: scaleAnimation.current },
                { rotate: rotateAnimation.current },
            ],
            opacity: opacityAnimation.current,
        };
    };
    const handleOnLayout = (e) => {
        if (rendered.current) {
            return;
        }
        rendered.current = true;
        const cHeight = Math.ceil(height);
        const negativeHeight = cHeight * -1;
        const shapeHeight = e.nativeEvent.layout.height;
        yAnimation.current = position.interpolate({
            inputRange: [negativeHeight, 0],
            outputRange: [cHeight, 0],
        });
        opacityAnimation.current = yAnimation.current.interpolate({
            inputRange: [0, cHeight - shapeHeight],
            outputRange: [1, 0],
        });
        scaleAnimation.current = yAnimation.current.interpolate({
            inputRange: [0, 15, 30, cHeight],
            outputRange: [0, 1.2, 1, 1],
        });
        xAnimation.current = yAnimation.current.interpolate({
            inputRange: [0, cHeight / 2, height],
            outputRange: [0, 15, 0],
        });
        rotateAnimation.current = yAnimation.current.interpolate({
            inputRange: [0, cHeight / 4, cHeight / 3, cHeight / 2, cHeight],
            outputRange: ['0deg', '-2deg', '0deg', '2deg', '0deg'],
        });
        setTimeout(() => setAnimationsReady(true), 16);
    };
    return (react_1.default.createElement(react_native_1.Animated.View, { style: [styles.shapeWrapper, getAnimationStyle(), style], onLayout: handleOnLayout }, children));
};
exports.AnimatedShape = AnimatedShape;
const styles = react_native_1.StyleSheet.create({
    shapeWrapper: {
        backgroundColor: constants_1.Colors.transparent,
        bottom: 0,
        position: 'absolute',
    },
});
